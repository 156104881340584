import store from "../../redux/store";
import { signOut } from "../../redux/slices/userSlice";
import standardBizApiClient from "../BizApi/standardBizApiClient";
import pagePath from "../constants/pagePath";

export const handleSignout = async () => {
    await standardBizApiClient.account_signout();
    sessionStorage.clear();
    localStorage.clear();
    store.dispatch(signOut());
};
