import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setCertList, setCertCodeSubscribedList, setCertCodeInFocus } from "../../../redux/slices/practiceSlice";
import PracticeSubFirstCertFlowCertList from "./PracticeSubFirstCertFlowCertList";
import CertSubscribe from "../../../components/certSubscribe/CertSubscribe";
import CertSubscribeDisallowed from "../../../components/certSubscribe/CertSubscribeDisallowed";
import PageWrapper from "../../../components/pageWrapper/PageWrapper";
import useIsCertSubscribeAllowed from "../../../hooks/useIsCertSubscribeAllowed";
import standardBizApiClient from "../../../util/BizApi/standardBizApiClient";
import pagePath from "../../../util/constants/pagePath";
import "./practiceSubFirstCertFlow.css";

// if the user goes to the practice page without any subscriptions (like after signing in for the first time)
// then this flow prompts the user to subscribe to a cert
const PracticeSubFirstCertFlow = () => {
    const [ pageStep, setPageStep ] = useState("cert_list"); // "cert_list" | "cert_subscribe"
    const [ selectedCert, setSelectedCert ] = useState(null); // index from certList
    const [ flowErrorMessage, setFlowErrorMessage ] = useState(null);
    const { certList, certCodeSubscribedList } = useSelector((state) => state.practice);
    const [ isCertSubscribeAllowed ] = useIsCertSubscribeAllowed();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        if (certList && certList.length === 0) {
            refreshCerts();
        }
    }, []);

    useEffect(() => {
        if (certCodeSubscribedList.length > 0 && pageStep !== "cert_subscribe") {
            navigate(pagePath.practice);
        }
    }, [certCodeSubscribedList]);

    const handleCertSelected = cert => {
        setSelectedCert(cert);
        setPageStep("cert_subscribe");
    }

    const handleCertSubscribeCancel = () => {
        setPageStep("cert_list");
        setSelectedCert(null);
    }

    const refreshCerts = async () => {
        const certListRes = await standardBizApiClient.certification_list();
        if (certListRes?.is_error) {
            setFlowErrorMessage("Error while getting active certifications, please refresh to try again.");
            return;
        }
        const { cert_code_in_focus, cert_code_subscribed_list, cert_list } = certListRes.certs;
        dispatch(setCertCodeInFocus(cert_code_in_focus));
        dispatch(setCertCodeSubscribedList(cert_code_subscribed_list));
        dispatch(setCertList(cert_list));
        setFlowErrorMessage(null);
    }

    const commonPageWrapperProps = {
        className: "practice-no-subscribed-certs"
    }

    if (flowErrorMessage !== null) {
        <Alert severity="error" style={{ marginTop: "15px" }}>
            {flowErrorMessage}
        </Alert> 
    }

    if (pageStep === "cert_list") {
        return (
            <PageWrapper 
                {...commonPageWrapperProps}
                title="Certifications"
                subTitle="Which certification do you wanna practice?"
            >
                <PracticeSubFirstCertFlowCertList 
                    onCertSelect={handleCertSelected}
                />
            </PageWrapper>
        )
    }

    if (pageStep === "cert_subscribe") {
        const { code, name } = selectedCert.cert;        
        const isAllowed = isCertSubscribeAllowed();
        return (
            <PageWrapper {...commonPageWrapperProps}>
                {isAllowed === true ? (
                    <CertSubscribe 
                        certCode={code} 
                        certName={name} 
                        onCancel={handleCertSubscribeCancel}
                    />
                ) : (
                    <CertSubscribeDisallowed 
                        certName={name} 
                        onCancel={handleCertSubscribeCancel}
                        continuePath={pagePath.ma_redirect_practice_sub_cert}
                    />
                )}
            </PageWrapper>
        )
    }
}

export default PracticeSubFirstCertFlow;