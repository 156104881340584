import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import moment from "moment";
import standardBizApiClient from "../../../../util/BizApi/standardBizApiClient";
import LoadingButton from "../../../../components/customMui/LoadingButton";
import Alert from "../../../../components/customMui/Alert";
import Button from "../../../../components/customMui/Button";
import CircularProgress from "../../../../components/customMui/CircularProgress";
import PracticeHistoryQuestions from "../../practiceHistoryQuestions/PracticeHistoryQuestions";
import getCssVariable from "../../../../util/functions/getCssVariable";
import "./practiceDomainHistory.css";

const PracticeDomainHistory = ({ domain }) => {
    const { certList, certCodeInFocus } = useSelector((state) => state.practice);
    const [ showPageLoader, setShowPageLoader] = useState(true);
    const [ certInFocus, setCertInFocus ] = useState(null);
    const [ showDetailsLoader, setShowDetailsLoader ] = useState(null);
    const [ errorMessage, setErrorMessage ] = useState(null);
    const [ examSummary, setExamSummary ] = useState(null);

    useEffect(() => {
        handleFormatCert();
    }, [certList, certCodeInFocus]);

    const handleFormatCert = () => {
        let newCertInFocus = certList.filter(cert => cert.cert.code === certCodeInFocus);
        if (newCertInFocus.length === 0) {
            setShowPageLoader(false);
            return;
        }
        newCertInFocus = newCertInFocus[0];
        setCertInFocus(newCertInFocus);
        setShowPageLoader(false);
    }
    
    const handleShowExamSummary = async (examId) => {
        setErrorMessage(null);
        setShowDetailsLoader(examId);
        const examSummaryRes = await standardBizApiClient.exam_summary(examId);
        if (examSummaryRes?.is_error) {
            setErrorMessage("Error while getting test details, please try again soon.");
            setShowDetailsLoader(null);
            return;
        }
        setExamSummary(examSummaryRes);
        setShowDetailsLoader(null);
    }

    const handleHideExamSummary = () => {
        setExamSummary(null);
    }    

    if (!domain || showPageLoader) {
        return null;
    }

    if (domain.test_history.length === 0) {
        return (
            <p>There are no completed tests for {domain?.domain?.name ? domain.domain.name : "this domain"}.</p>
        )
    }    

    return (
        <div style={{ width: "100%" }}>
            {errorMessage ? <Alert severity="error">{errorMessage}</Alert> : null}
            {examSummary ? (
                <>
                    <Button
                        variant="outlined"
                        customProps={{
                            onClick: handleHideExamSummary,
                            sx: { width: "100%", marginBottom: "20px" }
                        }}
                    >
                        Back
                    </Button>
                    <PracticeHistoryQuestions examSummary={examSummary} />
                </>
            ) : (
                domain.test_history.map((test, index) => {
                    const createdDt = moment.utc(test.created_dt);
                    const createdDate = createdDt.local().format("M/D/YYYY h:mm A");
                    return (
                        <div key={index} className="practice-domain-history-list-item">
                            <CircularProgress
                                value={Math.floor(test.score_factor * 100)}
                                min={0}
                                max={100}
                                size={60}
                                thickness={4}
                                color={test.score_factor >= certInFocus.full_test.passing_score_factor ? getCssVariable("--wnp-color-correct") : getCssVariable("--wnp-color-incorrect")}
                                backgroundColor={null}
                                label={Math.floor(test.score_factor * 100) + "%"}
                            />
                            <div className="practice-domain-history-list-item-data-list">
                                <p>{createdDate}</p>
                                <p>{parseInt(test.peq_count)} Questions</p>
                            </div>
                            <LoadingButton
                                customProps={{
                                    onClick: () => handleShowExamSummary(test.practice_exam_id),
                                    loading: showDetailsLoader === test.practice_exam_id,
                                    sx: { width: "100px" }
                                }}
                            >
                                Details
                            </LoadingButton>
                        </div>
                    )
                })
            )}
        </div>
    )
}

export default PracticeDomainHistory;