import { combineReducers } from "redux";
import storage from "redux-persist/lib/storage";
import { persistReducer } from "redux-persist";

import deviceContextReducer from "./slices/deviceContextSlice";
import exampleReducer from "./slices/exampleSlice";
import examReducer from "./slices/examSlice";
import networkReducer from "./slices/networkSlice";
import practiceReducer from "./slices/practiceSlice";
import userReducer from "./slices/userSlice";

const reducers = combineReducers({
    device_context: deviceContextReducer,
    example: exampleReducer,
    exam: examReducer,    
    network: networkReducer,
    practice: practiceReducer,
    user: userReducer,    
})

const rootReducer = (state, action) => {    
    if (action.type === "user/signOut") {
        // persist reducers that should not be reset during signout
        const appScopeReducerNames = ['device_context']
        const newState = {};
        appScopeReducerNames.forEach(name => {
            if (state?.[name]) {
                newState[name] = state[name];
            }
        })

        localStorage.removeItem('persist:root');
        return reducers(newState, action);
    }

    return reducers(state, action);
};

export default persistReducer(
    {
        key: "root",
        storage,
    },
    rootReducer
);