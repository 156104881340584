import React, { useState, useEffect } from "react";
import { Skeleton } from "@mui/material";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import getCertColors from "../../util/functions/getCertColors";
import LoadingButton from "../customMui/LoadingButton";
import Button from "../customMui/Button";
import TextField from "../customMui/TextField";
import "./certSubscribe.css";

const CertSubscribeSkeleton = ({ certCode, certName, onCancel }) => {
    const [ certColors, setCertColors ] = useState({});

    useEffect(() => {
        setCertColors(getCertColors(certCode));
    }, []);

    return (
        <div className="cert-subscribe">
            <div className="cert-subscribe-price-container">
                <div
                    className="cert-subscribe-price-cert-name font-header-bold"
                    style={{ 
                        borderColor: certColors?.color ? certColors.color : "#ffffff", 
                        color: certColors?.color ? certColors.color : "#ffffff"
                    }}
                >
                    {certName}
                </div>
                <div className="cert-subscribe-price-content">
                    <Skeleton animation="wave" sx={{ width: "150px" }} />                
                </div>
            </div>

            <div className="cert-subscribe-feature-list">
                <div className="cert-subscribe-feature-item">
                    <div className="cert-subscribe-feature-icon">
                        <CheckCircleIcon />
                    </div>
                    <Skeleton animation="wave" sx={{ width: "230px" }} /> 
                </div>
                <div className="cert-subscribe-feature-item">
                    <div className="cert-subscribe-feature-icon">
                        <CheckCircleIcon />
                    </div>
                    <Skeleton animation="wave" sx={{ width: "180px" }} /> 
                </div>
            </div>

            <hr className="cert-subscribe-hr" />

            <form className="cert-subscribe-promo-code-form">
                <TextField
                    disabled={true}
                    label="Promo Code"
                />
                <LoadingButton
                    variant="contained"
                    customProps={{
                        disabled: true
                    }}
                >
                    Apply
                </LoadingButton>
            </form>

            <form className="cert-subscribe-payment-method-form">
                <Skeleton animation="wave" sx={{ width: "140px" }} /> 
                <Skeleton animation="wave" sx={{ width: "100%" }} /> 
            </form>

            <div className="cert-subscribe-payment-action-buttons">
                <LoadingButton
                    variant="contained"
                    customProps={{
                        disabled: true
                    }}
                >
                    Subscribe
                </LoadingButton>
                <Button
                    variant="outlined"
                    customProps={{
                        onClick: onCancel
                    }}
                >
                    Back
                </Button>
            </div>
        </div>
    )
}

export default CertSubscribeSkeleton;
