import React from "react";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import { Skeleton } from "@mui/material";
import "./practiceAnswer.css";

const PracticeAnswer = ({
    answerData={ key: "a", name: "This is the answer text" },
    answerState="disabled", // "loading" | "disabled" | "correct" | "incorrect"
    onClick=(key) => {},
    size="medium", // "medium" | "small"
    showEmptyBubble=true
}) => {

    const handleAnswerClick = (key) => {
        if (answerState === "disabled" || answerState === "loading") {
            return;
        }
        onClick(key);
    }

    const getAnswerClassName = () => {
        let answerClassName = "";
        switch(answerState) {
            case("selected"):
                answerClassName = "practice-answer-selected"
                break;
            case("disabled"):
                answerClassName = "practice-answer-disabled"
                break;
            case("correct"):
                answerClassName = "practice-answer-correct"
                break;
            case("incorrect"):
                answerClassName = "practice-answer-incorrect"
                break;
            default:
                answerClassName = ""
        }
        return answerClassName;
    }

    if (answerState === "loading") {
        return (
            <div 
                className={`practice-answer practice-answer-disabled`}
            >
                <div className="practice-answer-option-text">
                    <p style={{ width: "15px" }} className="practice-answer-option-key font-header-regular">
                        <Skeleton animation="wave" sx={{ width: "100%" }} />
                    </p>
                    <p style={{ width: "100%" }}><Skeleton animation="wave" sx={{ width: "35%" }} /></p>
                </div>                            
                <div className="practice-answer-option-bubble"></div>
            </div>
        )
    }

    return (
        <div  
            className={`practice-answer practice-answer-${size} ${getAnswerClassName()}`}
            onClick={() => handleAnswerClick(answerData.key)}
        >
            <div className="practice-answer-option-text">
                <p className="practice-answer-option-key font-header-regular">
                    {answerData.key.toUpperCase()}.
                </p>
                <p style={{ textAlign: "left" }}>{answerData.name}</p>
            </div>                            
            <div className={`practice-answer-option-bubble ${showEmptyBubble ? "" : "practice-answer-option-bubble-no-empty"}`}>
                <div className="practice-answer-option-bubble-inner"></div>
                <div className="practice-answer-option-bubble-correct">
                    <CheckCircleIcon />
                </div>
                <div className="practice-answer-option-bubble-incorrect">
                    <CancelIcon />
                </div>
            </div>
        </div>
    )
}

export default PracticeAnswer;
