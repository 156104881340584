import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import Button from "../customMui/Button";
import "./certSubscribeDisallowed.css";

const CertSubscribeDisallowed = ({ 
    certName, 
    onCancel, 
    continuePath // such as pagePath.ma_redirect_practice_sub_cert
}) => {
    const { platform, os } = useSelector((state) => state.device_context);

    // determine which message to show
    const message = useMemo(() => {
        let retval = (
            <p>Please contact our support team to subscribe to {certName}</p> // default, not meant to be used
        );
        if (platform === "mobile_app") {
            retval = (
                <div className="cert-subscribe-disallowed-multiline-message">
                    <p>Ready to start practicing for your {certName} certification?</p>
                    <p>To get started, simply finish the subscription process on our website by clicking the button below.</p>
                    <p>Then come back to the app after subscribing to start practicing.</p>                    
                </div>
            )
        }        
        return retval;
    }, [platform, os]);

    // determine which button to show
    const actionButton = useMemo(() => {
        let retval = null;
        if (platform === "mobile_app") {
            retval = (
                <Button                
                    variant="contained"
                    customProps={{
                        onClick: () => window.location.href = continuePath
                    }}
                >
                    Continue in browser
                </Button> 
            )
        }        
        return retval;
    }, [platform, os]);

    return (
        <div className="cert-subscribe-disallowed">
            <h4 className="font-header-light">Subscribe to {certName}</h4>
            {message}
            {actionButton}
            <Button                
                variant="outlined"
                customProps={{
                    onClick: onCancel
                }}
            >
                Back
            </Button>  
            <p className="cert-subscribe-disallowed-footer">
                Any questions? Please reach out to us at <a href="mailto:support@wannapractice.com">support@wannapractice.com</a>
            </p>
        </div>
    )
}

export default CertSubscribeDisallowed;
