const getCssVariable = (name, defaultValue="") => {
    const value = getComputedStyle(document.documentElement).getPropertyValue(name);
    // if value does not exist, return default
    if (value === "") return defaultValue;
    // else return value
    return value;
}

// some places require the css variables before they exist in the document
// this is a temporary solution (hardcoding them here)
export const getCssVariableHardcoded = (name) => {
    const cssVariableStorage = {
        "--wnp-color-font": "#0f0f0f",
        "--wnp-color-primary-dark": "#1c7ca3",
        "--wnp-color-primary": "#1f87b2",
        "--wnp-color-primary-light": "#78b7d1",
        "--wnp-color-primary-extralight": "#d2e7f0",
        "--wnp-color-secondary": "#2b363d",
        "--wnp-color-secondary-light": "#80868b",
        "--wnp-color-secondary-extralight": "#d5d7d8",
        "--wnp-color-cissp-dark": "#004ea9",
        "--wnp-color-cissp": "#0055b8",
        "--wnp-color-cissp-extralight": "#eaf1f9",
        "--wnp-color-cism-dark": "#009fab",
        "--wnp-color-cism": "#00adbb",
        "--wnp-color-cism-extralight": "#eaf8f9",
        "--wnp-color-sscp-dark": "#004f48",
        "--wnp-color-sscp": "#00564e",
        "--wnp-color-sscp-extralight": "#eaf1f0",
        "--wnp-color-ccsp-dark": "#a06b00",
        "--wnp-color-ccsp": "#af7500",
        "--wnp-color-ccsp-extralight": "#f8f4ea",
        "--wnp-color-ccsk-dark": "#ac471f",
        "--wnp-color-ccsk": "#bc4d22",
        "--wnp-color-ccsk-extralight": "#f9f0ed",
        "--wnp-color-cisa-dark": "#0bbbe3",
        "--wnp-color-cisa": "#02aff9",
        "--wnp-color-cisa-extralight": "#e8f3f8fc",
        "--wnp-font-body-regular": "adelle_sans-regular",
        "--wnp-color-crisc-dark": "#aba808",
        "--wnp-color-crisc": "#b2bb0d",
        "--wnp-color-crisc-extralight": "#edeed9",
        "--wnp-color-cissp-2024-dark": "#F76B4D",
        "--wnp-color-cissp-2024": "#F56853",
        "--wnp-color-cissp-2024-extralight":"#F8F4EA"
    }
    return cssVariableStorage[name];
}

export default getCssVariable;