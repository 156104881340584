import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setCertCodeInFocus, setCertCodeSubscribedList, setCertList } from "../../../redux/slices/practiceSlice";
import { setIsExamInProgress, setLatestExamType } from "../../../redux/slices/examSlice";
import Modal from "../../../components/customMui/modal/Modal";
import Button from "../../../components/customMui/Button";
import LoadingButton from "../../../components/customMui/LoadingButton";
import Alert from "../../../components/customMui/Alert";
import standardBizApiClient from "../../../util/BizApi/standardBizApiClient";
import pagePath from "../../../util/constants/pagePath";

const PracticeExamAlreadyInProgress = () => {
    const { isExamInProgress } = useSelector(state => state.exam);
    const [ showCancelLoader, setShowCancelLoader ] = useState(false);
    const [ errorMessage, setErrorMessage ] = useState(null);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const handleCancelExam = async () => {
        if (showCancelLoader) return;

        setShowCancelLoader(true);
        setErrorMessage(null);
        const cancelExamRes = await standardBizApiClient.exam_discard();
        if (cancelExamRes?.is_error) {
            setShowCancelLoader(false);
            setErrorMessage("Error while cancelling the test in progress, please try again soon.");
            return;
        }

        const certListRes = await standardBizApiClient.certification_list();
        const { cert_code_in_focus, cert_code_subscribed_list, cert_list } = certListRes.certs;
        dispatch(setCertCodeInFocus(cert_code_in_focus));
        dispatch(setCertCodeSubscribedList(cert_code_subscribed_list));
        dispatch(setCertList(cert_list));

        dispatch(setIsExamInProgress(false));
        dispatch(setLatestExamType(null));
        setShowCancelLoader(false);
    }

    const handleContinueExam = () => {
        if (showCancelLoader) return;

        navigate(pagePath.practice_test);
    }

    return (
        <Modal
            onClose={null}
            title={<h5>Continue Previous Test?</h5>}
            customProps={{
                open: isExamInProgress === true || false
            }}
        >
            {errorMessage ? <Alert severity="error">{errorMessage}</Alert> : null}
            <p style={{ marginBottom: "30px" }}>
                You have a test in progress from your last session. Would you like to continue or cancel it?
            </p>
            <div style={{ display: "flex", alignItems: "center" }}>
                <LoadingButton
                    variant="text"
                    customProps={{
                        onClick: () => handleCancelExam(),
                        loading: showCancelLoader,
                        sx: { margin: "0 10px" }
                    }} 
                >
                    Cancel Test
                </LoadingButton>   
                <Button
                    variant="contained"
                    customProps={{
                        onClick: () => handleContinueExam(),
                        disabled: showCancelLoader,
                        sx: { margin: "0 10px" }
                    }} 
                >
                    Continue Test
                </Button>  
            </div>   
        </Modal>
    )
}

export default PracticeExamAlreadyInProgress;