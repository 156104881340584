import React from "react";
import { CardElement } from "@stripe/react-stripe-js";
import getCssVariable from "../../util/functions/getCssVariable";

const StyledStripeCardElement = (props) => {
    return (
        <CardElement 
            {...props}
            options={{
                style: {
                    base: {
                      color: getCssVariable("--wnp-color-font"),
                      fontSmoothing: "antialiased",
                      fontSize: "16px",
                      "::placeholder": {
                        color: "#666",
                      },
                      iconColor: getCssVariable("--wnp-color-primary-dark"),
                    }
                }
            }}
        />
    )
}

export default StyledStripeCardElement;