import { createSlice } from "@reduxjs/toolkit"

const initialState = {
    last_request_url: ""
}

export const NetworkSlice = createSlice({
    name: "network",
    initialState: initialState,
    reducers: {
        setLastRequestUrl: (state, action) => {
            state.last_request_url = action.payload;
        },
        resetNetwork: (state, action) => {
            // looping keys because we can't set state = initialState
            // probably a better way of doing this. havent looked into it yet
            for (const key in initialState) {
                state[key] = initialState[key];
            }
        }
    }
})

export const { setLastRequestUrl, resetNetwork } = NetworkSlice.actions

export default NetworkSlice.reducer