import axios from "axios";
import { setLastRequestUrl } from "../../redux/slices/networkSlice";
import { handleSignout } from "./handleSignout";

export default {
    setup: store => {
        axios.interceptors.request.use(
            request => {
                store.dispatch(setLastRequestUrl(request.url))
                return request;
            },
            error => {
                return Promise.reject(error);
            }
        );
        axios.interceptors.response.use(
            response => {
                return response;
            },
            error => {
                if (
                    (
                        error?.response?.status === 401 ||
                        error?.response?.data?.error?.context_code === "UNAUTHORIZED_SESSION"
                    ) &&
                    store.getState()?.user?.signedIn === true
                ) {
                    handleSignout();
                }
                return Promise.reject(error);
            }
        );
    }
};
