import getCssVariable from "./getCssVariable";

const getCertColorShade = (certCode, shade=null) => {
    let certColor = getCssVariable(`--wnp-color-${certCode}${shade ? `-${shade}`: ""}`, false);
    if (!certColor) certColor = getCssVariable("--wnp-color-font", "#1fa6df");
    return certColor;
}

const getCertColors = certCode => {
    return {
        color: getCertColorShade(certCode),
        color_dark: getCertColorShade(certCode, "dark"),
        color_extralight: getCertColorShade(certCode, "extralight")
    }
}

export default getCertColors;
