import React from "react";
import { useSelector } from "react-redux";
import { Routes, Route, Navigate } from "react-router-dom";
import pagePath from "./util/constants/pagePath";

import Layout from "./components/layout/Layout";
import useDeviceContextUpdater from "./hooks/useDeviceContextUpdater";

import Home from "./pages/home/Home";
import Login from "./pages/login/Login";
import SupportLogin from "./pages/support/SupportLogin";
import Signup from "./pages/signup/Signup";
import SignupConfirm from "./pages/signupConfirm/SignupConfirm";
import ResetPasswordInitiate from "./pages/resetPasswordInitiate/ResetPasswordInitiate";
import ResetPassword from "./pages/resetPassword/ResetPassword";
import Support from "./pages/support/Support";
import Account from "./pages/account/Account";
import Practice from "./pages/practice/Practice";
import PracticeTest from "./pages/practice/practiceTest/PracticeTest";
import PracticeSubFirstCertFlow from "./pages/practice/practiceSubFirstCertFlow/PracticeSubFirstCertFlow";
import TimeoutWarning from "./components/timeoutWarning/TimeoutWarning";
import WhileBrowserRedirect from "./pages/mobileAppAction/WhileBrowserRedirect";

const AppRoutes = () => {
    useDeviceContextUpdater();
    const signedIn = useSelector((state) => state.user.signedIn);

    // support login is separate from other routes because it handles session differently
    // if there is already a session on the support login page, 
    //  then it signs out before doing the support login,
    //  instead of redirecting to the authorized routes
    if (window.location.pathname.startsWith(pagePath.support_login)) {
        return (
            <Routes>
                <Route exact path={pagePath.support_login} element={<SupportLogin />} />
            </Routes>
        )
    }

    // mobile app redirect routes
    if (window.location.pathname.startsWith(pagePath.base_ma_redirect)) {
        return (
            <Routes>
                <Route exact path={pagePath.ma_redirect_account} element={<WhileBrowserRedirect continuePath={pagePath.account} />} />
                <Route exact path={pagePath.ma_redirect_practice_sub_cert} element={<WhileBrowserRedirect continuePath={pagePath.practice_no_subscribed_certs} />} />
                <Route exact path={pagePath.ma_redirect_signup} element={<WhileBrowserRedirect continuePath={pagePath.login} />} />
            </Routes>
        )
    }

    // Unauthorized user routes
    if (signedIn === false) {
        return (
            <Layout>
                <Routes>
                    {/* Blank path is used in health check */}
                    {/* <Route exact path={pagePath.healthcheck} element={<Home />} /> */}
                    {/* <Route exact path={pagePath.home} element={<Home />} /> */}
                    <Route exact path={pagePath.healthcheck} element={<Login />} />
                    <Route exact path={pagePath.home} element={<Login />} />
                    <Route exact path={pagePath.login} element={<Login />} />
                    <Route exact path={pagePath.signup} element={<Signup />} />
                    <Route exact path={pagePath.signup_confirm} element={<SignupConfirm />} />
                    <Route exact path={pagePath.reset_password_initiate} element={<ResetPasswordInitiate />} />
                    <Route exact path={pagePath.reset_password} element={<ResetPassword />} />
                    <Route path="*" element={<Navigate to={pagePath.unauthenticated_default} replace />} />
                </Routes>
            </Layout>
        );
    }

    // Authorized user routes
    return (
        <Layout>
            <TimeoutWarning />

            <Routes>
                <Route exact path={pagePath.account} element={<Account />} />
                <Route exact path={pagePath.support} element={<Support />} /> 
                <Route exact path={pagePath.practice_test} element={<PracticeTest />} />
                <Route exact path={pagePath.practice_no_subscribed_certs} element={<PracticeSubFirstCertFlow />} />
                <Route exact path={pagePath.practice} element={<Practice />} />
                <Route path="*" element={<Navigate to={pagePath.authenticated_default} replace />} />
            </Routes>
        </Layout>
    );
}

export default AppRoutes;
