import React from "react";
import MuiSelect from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import { styled } from "@mui/material/styles";
import { getCssVariableHardcoded } from "../../util/functions/getCssVariable";

const primaryColor = getCssVariableHardcoded("--wnp-color-primary");
const secondaryColor = getCssVariableHardcoded("--wnp-color-secondary");
const fontFamily = getCssVariableHardcoded("--wnp-font-body-regular");

// Future note:
    // dont have the time right now to figure out styles for this one
    // may have to make a new select component without MUI
    
const StyledMuiSelect = styled(MuiSelect)({
    // "& .MuiOutlinedInput-root": {
    //     fontFamily: fontFamily,
    //     "&.Mui-focused fieldset": {
    //         borderColor: primaryColor
    //     },
    // }
})

const Select = ({ label=null, labelId=null, customProps, children }) => {
    return (
        <> 
            {label ? (
                <InputLabel id={labelId}>{label}</InputLabel>
            ): null}
            <StyledMuiSelect
                variant="outlined"
                labelId={labelId}
                {...customProps}
            >
                {children}
            </StyledMuiSelect>
        </>
    )
}

export default Select;