
import React, { useState, useEffect } from 'react';

 const SupportModal = () => {
  const [modalOpen, setModalOpen] = useState(false);

  const handleBack = () => {
    window.history.back(); // Go back to the previous page
  };
  const headingStyle = {
    textAlign: 'center', // Added to remove default margin
  };
  const paragraphStyle = {
    marginBottom: '8px',
    textAlign: 'center', // Added margin bottom for spacing
  };
  const modalContainerStyle = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '80vh',
  };
  const backButtonStyle = {
    backgroundColor: '#489ae6',
    color: 'white',
    padding: '10px 20px',
    fontSize: '16px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '120px',
    margin: '0 auto',
    borderRadius: '10px',
    border: '2px',
    cursor: 'pointer',
  };

  return (
    <div  style={modalContainerStyle}>
        <div>
          <h2 style={headingStyle}>Support</h2>
          <p style={paragraphStyle}>
            If you need any support please contact us at<br/>
            <a href="mailto:support@wannapractice.com">support@wannapractice.com</a>
          </p>
          <div>
            <button style={backButtonStyle} onClick={handleBack}>
              Back
            </button>
          </div>
        </div>
    </div>
  );
};

export default SupportModal;