/* 
getClockTimeFromSeconds
    converts a number of seconds into hours, minutes, and seconds
    examples:
        getClockTimeFromSeconds(40) => {
            "hours": 0,
            "minutes": 0,
            "seconds": 40,
            "clock": "0:40"
        }
        getClockTimeFromSeconds(75) => {
            "hours": 0,
            "minutes": 1,
            "seconds": 15,
            "clock": "01:15"
        }
        getClockTimeFromSeconds(3750) => {
            "hours": 1,
            "minutes": 2,
            "seconds": 30,
            "clock": "01:02:30"
        }
*/
export const getClockTimeFromSeconds = (seconds) => {
    let result = {
        hours: 0,
        minutes: 0,
        seconds: 0,
        clock: ""
    }
    if (typeof seconds !== "number" || seconds < 0) {
        result.clock = getClockTime();
        return result;
    }
    
    let remainingSeconds = seconds;
    if (remainingSeconds >= 3600) {
        result.hours = Math.floor(remainingSeconds / 3600);
        remainingSeconds = remainingSeconds % 3600;
    }
    if (remainingSeconds >= 60) {
        result.minutes = Math.floor(remainingSeconds / 60);
        remainingSeconds = remainingSeconds % 60;
    }
    result.seconds = remainingSeconds;

    result.clock = getClockTime(
        result.hours,
        result.minutes,
        result.seconds
    );

    return result;
}

const getClockTime = (hours=0, minutes=0, seconds=0) => {
    let clockTime = "";

    if (hours) clockTime += `${hours}:`;
    
    if (minutes > 9 || !hours) clockTime += `${minutes}:`;
    else clockTime += `0${minutes}:`;

    if (seconds > 9) clockTime += `${seconds}`;
    else clockTime += `0${seconds}`;

    return clockTime;
}

export const getSentenceTimeFromSeconds = (seconds, includeSecondsInSentence=false) => {
    // just_minutes is the minutes without calculating hours (and rounding down seconds)
        // sentence              => "1 hour 20 minutes"
        // sentence_just_minutes => "80 minutes"
    let result = {
        hours: 0,
        minutes: 0,
        just_minutes: 0,
        seconds: 0,
        sentence: "",
        sentence_just_minutes: ""
    }
    if (typeof seconds !== "number" || seconds <= 0) {
        result.sentence = includeSecondsInSentence ? "0 seconds" : "0 minutes";
        result.sentence_just_minutes = "0 minutes";
        return result;
    }

    let remainingSeconds = seconds;
    if (remainingSeconds >= 3600) {
        result.hours = Math.floor(remainingSeconds / 3600);
        remainingSeconds = remainingSeconds % 3600;
    }
    if (remainingSeconds >= 60) {
        result.minutes = Math.floor(remainingSeconds / 60);
        remainingSeconds = remainingSeconds % 60;
    }
    result.seconds = remainingSeconds;

    if (seconds >= 60) {
        result.just_minutes = Math.floor(seconds / 60);
    }
    
    result.sentence = getSentenceTime(
        result.hours > 0 ? result.hours : null,
        result.minutes > 0 || (result.seconds > 0 && includeSecondsInSentence === false) ? result.minutes : null,
        result.seconds > 0 && includeSecondsInSentence === true ? result.seconds : null
    );

    result.sentence_just_minutes = getSentenceTime(
        null, 
        result.just_minutes,
        null
    );

    return result;
}

const getSentenceTime = (hours=null, minutes=null, seconds=null) => {
    let sentenceTime = "";

    if (hours !== null) {
        sentenceTime += `${hours} hour${hours !== 1 ? "s" : ""} `;
    }

    if (minutes !== null) {
        sentenceTime += `${minutes} minute${minutes !== 1 ? "s" : ""} `;
    }

    if (seconds !== null) {
        sentenceTime += `${seconds} second${seconds !== 1 ? "s" : ""}`;
    }

    sentenceTime = sentenceTime.trim();
    return sentenceTime;
}