import React, { useState } from "react";
import TextField from "../../components/customMui/TextField";
import LoadingButton from "../../components/customMui/LoadingButton";
import Alert from "../../components/customMui/Alert";
import standardBizApiClient from "../../util/BizApi/standardBizApiClient";
import "./resetPasswordInitiate.css";

const ResetPasswordInitiate = () => {
    const [submitLoading, setSubmitLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);
    const [emailAddress, setEmailAddress] = useState("");
    const [showSuccess, setShowSuccess] = useState(false);

    const handleResetPasswordInitiate = async e => {
        e.preventDefault();

        setErrorMessage(null);
        setSubmitLoading(true);

        const resetPasswordInitiateRes = await standardBizApiClient.account_reset_password_initiate(emailAddress);
        if (resetPasswordInitiateRes?.is_error) {
            setErrorMessage("Error while handling reset password request, please check the entered email and try again.");
            setSubmitLoading(false);
            return;
        }
        setShowSuccess(true);
        setSubmitLoading(false);
    }

    if (showSuccess) {
        return (
            <div className="reset-password-initiate reset-password-initiate-success">
                <h2 className="font-header-light">Reset Password Email Sent</h2>
                <p>
                    WannaPractice Support (support@wannapractice.com) is sending an email to {emailAddress} to verify the reset password.
                </p>
                <p>
                    Please follow the instructions in that email.
                </p>
            </div>
        )
    }

    return (
        <div className="reset-password-initiate">
            <h2 className="font-header-light">Reset Password</h2>
            <form className="reset-password-initiate-form" onSubmit={handleResetPasswordInitiate}>
                {errorMessage ? (<Alert severity="error">{errorMessage}</Alert>) : null}
                <TextField
                    required
                    disabled={submitLoading}
                    value={emailAddress}
                    onChange={e => setEmailAddress(e.target.value)}
                    id="reset-password-initiate-email-address"
                    type="email"
                    label="Email Address"
                />
                <LoadingButton customProps={{ type: "submit", loading: submitLoading }}>Submit</LoadingButton>
            </form>
        </div>
    )
}

export default ResetPasswordInitiate;