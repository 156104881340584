import React, { useState } from "react";
import { useParams, Link } from "react-router-dom";
import TextField from "../../components/customMui/TextField";
import Button from "../../components/customMui/Button";
import LoadingButton from "../../components/customMui/LoadingButton";
import Alert from "../../components/customMui/Alert";
import standardBizApiClient from "../../util/BizApi/standardBizApiClient";
import pagePath from "../../util/constants/pagePath";
import "./resetPassword.css";

const ResetPassword = () => {
    const [ submitLoading, setSubmitLoading ] = useState(false);
    const [ errorMessage, setErrorMessage ] = useState(null);
    const [ password, setPassword ] = useState("");
    const [ confirmPassword, setConfirmPassword ] = useState("");
    const [ showSuccess, setShowSuccess ] = useState(false);
    const params = useParams();

    const handleResetPassword = async e => {
        e.preventDefault();

        setErrorMessage(null)
        if (password !== confirmPassword) {
            setErrorMessage("Passwords do not match")
            return;
        }

        setSubmitLoading(true);
        const token = params?.token;
        const resetPasswordRes = await standardBizApiClient.account_reset_password(password, token);
        if (resetPasswordRes?.is_error) {
            if (resetPasswordRes?.context_code === "EXPIRED") {
                setErrorMessage(<div>The reset password link has expired. Please generate a new link <Link to={pagePath.reset_password_initiate}>here</Link></div>);
                setSubmitLoading(false);
                return;
            }
            setErrorMessage("Error while resetting password, please try again soon.");
            setSubmitLoading(false);
            return;
        }
        setShowSuccess(true);
        setSubmitLoading(false);
    }

    if (showSuccess) {
        return (
            <div className="reset-password reset-password-success">
                <h2 className="font-header-light">Password Reset Successfully!</h2>
                <p>You can now login using your new password.</p>
                <Link to={pagePath.login}>
                    <Button>Continue to Login</Button>
                </Link>
            </div>
        )
    }

    return (
        <div className="reset-password">
            <h2 className="font-header-light">Reset Password</h2>
            <form className="reset-password-form" onSubmit={handleResetPassword}>
                {errorMessage ? (<Alert severity="error">{errorMessage}</Alert>) : null}
                <TextField 
                    required
                    disabled={submitLoading}
                    value={password}
                    onChange={e => setPassword(e.target.value)}
                    id="reset-password-password"
                    type="password"
                    label="New Password"
                />
                <TextField 
                    required
                    disabled={submitLoading}
                    value={confirmPassword}
                    onChange={e => setConfirmPassword(e.target.value)}
                    id="reset-password-confirm-password"
                    type="password"
                    label="Retype New Password"
                />
                <LoadingButton customProps={{ type: "submit", loading: submitLoading }}>Reset Password</LoadingButton>
            </form>
        </div>
    )
}

export default ResetPassword;