import React, { useState, useEffect } from "react";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import StyledStripeCardElement from "../styledStripeCardElement/StyledStripeCardElement";
import Alert from "../customMui/Alert";
import LoadingButton from "../customMui/LoadingButton";
import Button from "../customMui/Button";

const StripeCardElementFormInner = ({ onCancel, onSubmit }) => {
    const [ isCardFormComplete, setIsCardFormComplete ] = useState(false);
    const [ showFormSubmitLoader, setShowFormSubmitLoader ] = useState(false);
    const [ errorMessage, setErrorMessage ] = useState(null);
    const stripe = useStripe();
    const elements = useElements();

    const handleFormSubmit = async (e) => {
        e.preventDefault();
        if (showFormSubmitLoader) return;
        setErrorMessage(null);
        setShowFormSubmitLoader(true);

        if (isCardFormComplete === false) {
            setShowFormSubmitLoader(false);
            return;
        }

        // create stripe payment method
        const { error, paymentMethod } = await stripe.createPaymentMethod({
            type: "card",
            card: elements.getElement(CardElement)
        });
        if (error) {
            setShowFormSubmitLoader(false);
            let newErrorMessage = (
                <div>
                    Error while verifying card details. <br/>
                    Please try again, or reach out to us at <a href="mailto:support@wannapractice.com">support@wannapractice.com</a>
                </div>
            );
            if (error?.message) {
                newErrorMessage = error.message;
            }
            setErrorMessage(newErrorMessage);
            return;
        }

        const paymentMethodId = paymentMethod.id;

        const formSubmitUpstreamRes = await onSubmit(paymentMethodId);
        setShowFormSubmitLoader(false);
    }

    return (
        <div style={{ width: "100%", display: "flex", flexDirection: "column" }}>                
            {errorMessage ? <Alert severity="error" style={{ marginBottom: "20px" }}>{errorMessage}</Alert> : null}

            <form onSubmit={handleFormSubmit} style={{ width: "100%", marginBottom: "15px" }}>
                <StyledStripeCardElement onChange={state => setIsCardFormComplete(state.complete)} />
            </form>

            <div style={{ display: "flex", justifyContent: "center" }}>
                <Button                
                    variant="outlined"
                    customProps={{
                        disabled: showFormSubmitLoader,
                        onClick: onCancel,
                        sx: { marginRight: "10px" }
                    }}
                >
                    Cancel
                </Button> 
                <LoadingButton 
                    variant="contained"
                    customProps={{
                        disabled: !isCardFormComplete,
                        loading: showFormSubmitLoader,
                        onClick: handleFormSubmit
                    }}
                >
                    {showFormSubmitLoader ? "Submitting" : "Submit"}
                </LoadingButton>               
            </div>
        </div>
    )
}


export default StripeCardElementFormInner;
