import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux"; 
import { setTimeRemainingSeconds } from "../../../../redux/slices/examSlice";
import standardBizApiClient from "../../../../util/BizApi/standardBizApiClient";

/*
    PracticePing
        - send an exam ping request on an interval based on pingIntervalSeconds in redux

        - returns null
*/

const PracticePing = () => {
    const { pingIntervalSeconds } = useSelector(state => state.exam);
    const dispatch = useDispatch();
    let pingInterval = null;

    useEffect(() => {
        handleStopInterval();
        handleStartInterval();

        return () => {
            handleStopInterval();
        }
    }, [pingIntervalSeconds]);

    const handleStartInterval = () => {
        if (typeof pingIntervalSeconds !== "number") return;

        // the ping request should be sent before the expected time to account for network delay
        const actualPingIntervalSeconds = Math.round(pingIntervalSeconds * 0.8)
        const pingIntervalMs = actualPingIntervalSeconds * 1000;

        pingInterval = setInterval(() => {
            sendPing();
        }, pingIntervalMs);
    }

    const handleStopInterval = () => {
        if (pingInterval) {
            clearTimeout(pingInterval);
        }
    }

    const sendPing = async () => {
        const pingRes = await standardBizApiClient.exam_ping();
        if (pingRes?.is_error) {
            return;
        }
        const { time_remaining_seconds } = pingRes;
        dispatch(setTimeRemainingSeconds(time_remaining_seconds));
    }

    return null;
}

export default PracticePing;