import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import SignupForm from "./SignupForm";
import SignupDisallowed from "./SignupDisallowed";
import "./signup.css";
import pagePath from "../../util/constants/pagePath";

const Signup = () => {
    const { platform, os } = useSelector((state) => state.device_context);

    const isSignupAllowed = useMemo(() => {
        // ios app users have to use the browser to sign up
        if (platform === "mobile_app" && os === "ios") return false;
        return true;
    }, [platform, os])

    return (
        <div className="signup">
            <h2 className="font-header-light">Sign Up</h2>
            {isSignupAllowed === true ? (
                <SignupForm />
            ) : (
                <SignupDisallowed 
                    onCancel={() => window.location.href = pagePath.login}
                    continuePath={pagePath.ma_redirect_signup}
                />
            )}
        </div>
    )
}

export default Signup;