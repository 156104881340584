import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from 'react-router-dom';
import { setPlatform, setOs } from "../redux/slices/deviceContextSlice";

// listens for page changes and sets the platform and os query params in redux
// because the mobile app sends those query params and we need to keep track of them for webapp logic
const useDeviceContextUpdater = () => {
    const location = useLocation();
    const dispatch = useDispatch();

    useEffect(() => {
        onQueryParamChange(location.search)
    }, [location.search])

    // each redux value is updated only if it is defined in the query string
    const onQueryParamChange = (queryParamString) => {
        const searchParams = new URLSearchParams(queryParamString);
        
        const newPlatform = searchParams.get('platform') || undefined;
        if (newPlatform) dispatch(setPlatform(newPlatform));

        const newOs = searchParams.get('os') || undefined;
        if (newOs) dispatch(setOs(newOs));
    }

    return null;
}

export default useDeviceContextUpdater;