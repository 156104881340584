import React, { useState } from "react";
import PageWrapper from "../../components/pageWrapper/PageWrapper";
import CertSubscribe from "../../components/certSubscribe/CertSubscribe";
import CertSubscribeDisallowed from "../../components/certSubscribe/CertSubscribeDisallowed";
import SubscriptionManage from "./subscriptionManage/SubscriptionManage";
import AccountManage from "./accountManage/AccountManage";
import useIsCertSubscribeAllowed from "../../hooks/useIsCertSubscribeAllowed";
import pagePath from "../../util/constants/pagePath";
import AccountBanner from "./AccounBanner";
import "./account.css";


const Account = () => {
    const [ showCertSubscribePage, setShowCertSubscribePage ] = useState(false);
    const [ certSubscribeDetails, setCertSubscribeDetails ] = useState(null);
    const [ isCertSubscribeAllowed ] = useIsCertSubscribeAllowed();
    const isAllowed = isCertSubscribeAllowed();

    const handleShowCertSubscribe = (certCode, certName) => {
        setCertSubscribeDetails({
            code: certCode,
            name: certName
        })
        setShowCertSubscribePage(true);
    }

    const handleHideCertSubscribe = () => {
        setShowCertSubscribePage(false);
        setCertSubscribeDetails(null);
    }

    // show the cert subscribe page
    // the scope of this is bad. may just need to use redux
    if (showCertSubscribePage && certSubscribeDetails) {
        const isAllowed = isCertSubscribeAllowed();
        return (
            <PageWrapper>
                {isAllowed === true ? (
                    <CertSubscribe
                        certCode={certSubscribeDetails.code} 
                        certName={certSubscribeDetails.name} 
                        onCancel={handleHideCertSubscribe}
                    />
                ) : (
                    <CertSubscribeDisallowed 
                        certName={certSubscribeDetails.name} 
                        onCancel={handleHideCertSubscribe}
                        continuePath={pagePath.ma_redirect_account}
                    />
                )}
            </PageWrapper>
        )
    }

    // show the account page
    return (
        <PageWrapper
            className="account"
            title="Account"
        >
            {isAllowed === false ? (<AccountBanner/>) : null }
            <SubscriptionManage 
                onCertSubscribeClick={handleShowCertSubscribe} 
            />
            <AccountManage />
        </PageWrapper>
    )
}

export default Account;