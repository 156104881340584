import React, { useState } from "react";
import Alert from "../../../components/customMui/Alert";
import Button from "../../../components/customMui/Button";
import standardBizApiClient from "../../../util/BizApi/standardBizApiClient";
import { handleSignout } from "../../../util/functions/handleSignout";
import Modal from "../../../components/customMui/modal/Modal";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import "./accountManage.css"

const AccountManage = () => {
    const [showDeactivateModal, setShowDeactivateModal] = useState(false);
    const [deactivateErrorMessage, setDeactivateErrorMessage] = useState(null);
    const [deactivateLoading, setDeactivateLoading] = useState(false);
    const [isConfirmChecked, setIsConfirmChecked] = useState(false);
    const [isDeactivateDisabled, setIsDeactivateDisabled] = useState(true);

    const handleDeactivateConfirm = async () => {
        setDeactivateLoading(true);
        setDeactivateErrorMessage(null);
        const accountDeactivateRes = await standardBizApiClient.account_deactivate();
        if (accountDeactivateRes?.is_error) {
            setDeactivateLoading(false);
            const { code } = accountDeactivateRes;
            const codeMessageMap = {
                "DEACTIVATION_CERT_UNSUBSCRIBE_ERROR": "There was an error while unsubscribing from a certification. Please sign back in and try again. If problems persist, contact customer support at support@wannapractice.com",
                "USER_DEACTIVATE_ERROR": "There was an error while removing your account details. Please sign back in and try again. If problems persist, contact customer support at support@wannapractice.com",
                "default": "There was an error while closing your account. Please sign back in and try again. If problems persist, contact customer support at support@wannapractice.com"
            }
            const newErrorMessage = codeMessageMap?.[code] || codeMessageMap.default;
            setDeactivateErrorMessage(newErrorMessage);
            return;
        }
        handleSignout();
    }

    const handleDeactivateModalOpen = async () => {
        setShowDeactivateModal(true);
    }

    const handleDeactivateModalClose = () => {
        if (deactivateLoading) return;
        setShowDeactivateModal(false);
    }

    const onConfirmCheckChange = () => {
        setIsDeactivateDisabled(isConfirmChecked);
        setIsConfirmChecked(!isConfirmChecked);
    }

    return (
        <div className="account-manage" style={{ margin: "30px" }} >
            <div className="account-manage-content">
                <div onClick={() => handleDeactivateModalOpen()}>
                    <Button
                        variant="outlined"
                    >
                        Close Account
                    </Button>
                </div>
            </div>

            {showDeactivateModal ? (
                <Modal
                    title={<h5>Close Account</h5>}
                    onCloseIconClick={handleDeactivateModalClose}
                    customProps={{
                        open: showDeactivateModal,
                        onClose: handleDeactivateModalClose
                    }}
                >
                    <div className="account-manage-modal">
                        {deactivateErrorMessage ? (
                            <Alert
                                severity="error"
                                style={{ textAlign: "left", marginBottom: "20px" }}
                            >
                                {deactivateErrorMessage}
                            </Alert>
                        ) : null}

                        <Alert
                            severity="warning"
                            style={{ textAlign: "left", marginBottom: "20px" }}
                        >
                            Closing your account means you will immediately be unsubscribed from your subscriptions and lose access to your account.
                        </Alert>

                        <p
                            style={{ textAlign: "left", marginBottom: "20px" }}
                        >
                            If you want to cancel a subscription without closing your account, please go back and click unsubscribe next to the certification. 
                            Your account will stay active, but that subscription will be canceled.
                        </p>

                        <p
                            style={{ textAlign: "left", marginBottom: "20px" }}
                        >
                            After closing your account, you can sign up again later.
                        </p>

                        <FormControlLabel 
                            control={(
                                <Checkbox 
                                    checked={isConfirmChecked}
                                    onChange={onConfirmCheckChange}
                                    inputProps={{ "aria-label": "controlled" }}     
                                />
                            )} 
                            label="Please check the box to confirm the account closure" 
                        />

                        <div className="account-manage-modal-button-container">
                            <Button
                                variant="contained"
                                customProps={{
                                    onClick: handleDeactivateModalClose,
                                    disabled: deactivateLoading
                                }}
                            >
                                Go back
                            </Button>
                            <Button
                                variant="contained"
                                buttonColor="#a31c1c"
                                buttonColorSelected="#a31c1c"
                                buttonColorFilledBackground="#c92222"
                                customProps={{
                                    onClick: handleDeactivateConfirm,
                                    disabled: deactivateLoading || isDeactivateDisabled,
                                }}
                            >
                                Close Account
                            </Button>
                        </div>
                    </div>
                </Modal>
            ) : null}
        </div>
    )
}

export default AccountManage;