class AxiosErrorTransformer {
    constructor(client) {

    }
  
    // returns parsed error in a standard format
    parse = (requestError) => {
        let parsedError = {
            "code": "SYSTEM_ERROR",
            "context_code": "SYSTEM_ERROR",
            "message": "",
            "kvdetail": [],
            "is_error": true            
        }
        try {
            parsedError.code = requestError.response.data.error.code;
            parsedError.context_code = requestError.response.data.error.context_code;
            parsedError.message = requestError.response.data.error?.message ? requestError.response.data.error.message : "";
            parsedError.kvdetail = requestError.response.data.error?.kvdetail ? requestError.response.data.error.kvdetail : [];
        } catch(caughtError) {}
        return parsedError;
    }
}

export default AxiosErrorTransformer;