import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    isExamInProgress: false,
    latestExamType: null,
    latestExamId: null,
    pingIntervalSeconds: null,
    timeRemainingSeconds: 0
}

export const ExamSlice = createSlice({
    name: "exam",
    initialState: initialState,
    reducers: {
        setIsExamInProgress: (state, action) => {
            state.isExamInProgress = action.payload;
        },
        setLatestExamType: (state, action) => {
            state.latestExamType = action.payload;
        },
        setLatestExamId: (state, action) => {
            state.latestExamId = action.payload;
        },
        setPingIntervalSeconds: (state, action) => {
            state.pingIntervalSeconds = action.payload;
        },
        setTimeRemainingSeconds: (state, action) => {
            state.timeRemainingSeconds = action.payload;
        }
    }
})

export const { 
    setIsExamInProgress, 
    setLatestExamType, 
    setLatestExamId,
    setPingIntervalSeconds,
    setTimeRemainingSeconds
} = ExamSlice.actions

export default ExamSlice.reducer