import React from "react";
import { BrowserRouter } from "react-router-dom";
import AppRoutes from "./AppRoutes";

const AppRouter = () => {
    return (
        <BrowserRouter basename="/">
            <AppRoutes />
        </BrowserRouter>
    )
}

export default AppRouter;