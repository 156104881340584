import React from "react";
import MuiTextField from "@mui/material/TextField";
import { styled } from "@mui/material/styles";
import { getCssVariableHardcoded } from "../../util/functions/getCssVariable";

const primaryColor = getCssVariableHardcoded("--wnp-color-primary");
const secondaryLightColor = getCssVariableHardcoded("--wnp-color-secondary-light");
const fontFamily = getCssVariableHardcoded("--wnp-font-body-regular");

const StyledMuiTextField = styled(MuiTextField)({
    "& label.Mui-focused": {
        color: primaryColor,
    },
    "& .MuiInput-underline:after": {
        borderBottomColor: primaryColor,
    },
    "& .MuiInputLabel-root": {
        fontFamily: fontFamily, 
    },
    "& .MuiInputLabel-asterisk": {
        display: "none",
    },
    "& .MuiOutlinedInput-root": {
        fontFamily: fontFamily,
        "& fieldset": {
            borderColor: secondaryLightColor
        },
        "&:hover fieldset": {
            borderColor: primaryColor,
        },
        "&.Mui-focused fieldset": {
            borderColor: primaryColor,
        },
    },
})

const TextField = ({ ...custom }) => {
    return (
        <StyledMuiTextField
            variant="outlined"
            {...custom}
        />
    )
}

export default TextField;