import React, { useState, useEffect } from "react";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import StyledStripeCardElement from "../styledStripeCardElement/StyledStripeCardElement";
import CertSubscribeSkeleton from "./CertSubscribeSkeleton";
import CertSubscribeSuccess from "./CertSubscribeSuccess";
import Image from "../image/Image";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Alert from "../customMui/Alert";
import LoadingButton from "../customMui/LoadingButton";
import Button from "../customMui/Button";
import TextField from "../customMui/TextField";
import standardBizApiClient from "../../util/BizApi/standardBizApiClient";
import getCertColors from "../../util/functions/getCertColors";
import { getSentenceTimeFromSeconds } from "../../util/functions/time";
import "./certSubscribe.css";

const CertSubscribeInner = ({ certCode, certName, onCancel }) => {
    const [ showSkeletonLoader, setShowSkeletonLoader ] = useState(true);
    const [ showPromoCodeApplyLoader, setShowPromoCodeApplyLoader ] = useState(false);
    const [ showPromoCodeClearLoader, setShowPromoCodeClearLoader ] = useState(false);
    const [ showSubscribeLoader, setShowSubscribeLoader ] = useState(false);
    const [ errorMessage, setErrorMessage ] = useState(null);
    const [ priceDetails, setPriceDetails ] = useState(null);
    const [ showCardForm, setShowCardForm ] = useState(false);
    const [ isCardFormComplete, setIsCardFormComplete ] = useState(false);
    const [ cardDetails, setCardDetails ] = useState(null);
    const [ promoCodeInputValue, setPromoCodeInputValue ] = useState(""); // what the user has typed in the promo code input
    const [ promoCodeApplied, setPromoCodeApplied ] = useState(""); // the actual promo code that has been applied
    const [ certColors, setCertColors ] = useState(null);
    const [ showSuccessPage, setShowSuccessPage ] = useState(false);

    const stripe = useStripe();
    const elements = useElements();

    useEffect(() => {
        setCertColors(getCertColors(certCode));
        handleSetPriceDetails();
        handleSetCardDetails();
    }, []);

    // stop full page skeleton loader when pricingdetails and carddetails are retrieved
    useEffect(() => {
        if (priceDetails && cardDetails) {
            setShowSkeletonLoader(false);
        }
    }, [priceDetails, cardDetails]);

    const handleSetPriceDetails = async () => {
        const priceDetailsRes = await standardBizApiClient.certification_get_market_price(certCode);
        if (priceDetailsRes?.is_error) {
            setErrorMessage(
                <div>
                    Error while gathering certification data. <br/>
                    Please refresh the page and try again, or reach out to us at <a href="mailto:support@wannapractice.com">support@wannapractice.com</a>
                </div>
            );
            return;
        }
        const newPriceDetails = {
            ...priceDetailsRes,
            renewal_display_text: `Every ${priceDetailsRes.renewal_months} Months`
        }
        setPriceDetails(newPriceDetails);
    }

    const handleSetCardDetails = async () => {
        const cardDetailsRes = await standardBizApiClient.account_get_payment_method();
        if (cardDetailsRes?.is_error) {
            setErrorMessage(
                <div>
                    Error while gathering certification data. <br/>
                    Please refresh the page and try again, or reach out to us at <a href="mailto:support@wannapractice.com">support@wannapractice.com</a>
                </div>
            );
            return;
        }
        const newCardDetails = cardDetailsRes;
        const newShowCardForm = !Boolean(newCardDetails.detail);
        setCardDetails(newCardDetails);
        setShowCardForm(newShowCardForm);
    }

    const handleSubscribe = async (e) => {
        e.preventDefault();
        if (showSubscribeLoader) return;

        if (promoCodeApplied !== promoCodeInputValue) {
            if (promoCodeInputValue === "") {
                setErrorMessage(
                    <div>
                        The promo code entered does not match the one applied.<br/><br/>
                        If you would like to remove the promo code, click the Clear Promo Code button.
                    </div>
                );
                return;
            }
            setErrorMessage("Please apply or clear the promo code that was entered before you subscribe.");
            return;
        }

        setErrorMessage(null);
        setShowSubscribeLoader(true);

        // if the card form was shown, the user must enter a new card
        if (showCardForm === true) {
            if (isCardFormComplete === false) {
                setShowSubscribeLoader(false);
                return;
            }

            // create stripe payment method
            const { error, paymentMethod } = await stripe.createPaymentMethod({
                type: "card",
                card: elements.getElement(CardElement)
            });
            if (error) {
                setShowSubscribeLoader(false);
                let newErrorMessage = (
                    <div>
                        Error while verifying card details. <br/>
                        Please try again, or reach out to us at <a href="mailto:support@wannapractice.com">support@wannapractice.com</a>
                    </div>
                );
                if (error?.message) {
                    newErrorMessage = error.message;
                }
                setErrorMessage(newErrorMessage);
                return;
            }

            // save the stripe payment method id
            const paymentMethodId = paymentMethod.id;
            const setPaymentMethodRes = await standardBizApiClient.account_set_payment_method(paymentMethodId);
            if (setPaymentMethodRes?.is_error) {
                setShowSubscribeLoader(false);
                setErrorMessage(
                    <div>
                        Error while processing card details. <br/>
                        Please try again, or reach out to us at <a href="mailto:support@wannapractice.com">support@wannapractice.com</a>
                    </div>
                );
                return;
            }
        }

        // subscribe to the cert
        const subscribeRes = await standardBizApiClient.certification_subscribe(certCode, promoCodeApplied);
        if (subscribeRes?.is_error) {
            setShowSubscribeLoader(false);
            if (subscribeRes?.code === "INVALID_PROMOCODE") {
                setErrorMessage("The promo code is invalid or expired.");
                return;
            }

            const isErrorHandled = _handlePromoCodeRateLimitError(subscribeRes);
            if (isErrorHandled) return;

            setErrorMessage(
                <div>
                    Error while processing card details. <br/>
                    Please try again, or reach out to us at <a href="mailto:support@wannapractice.com">support@wannapractice.com</a>
                </div>
            );
            return;
        }

        setShowSuccessPage(true);
    }

    const handlePromoCodeSubmit = async (e) => {
        e.preventDefault();
        if (!promoCodeInputValue) return;
        
        setErrorMessage(null);
        setShowPromoCodeApplyLoader(true);

        const formattedPromoCode = promoCodeInputValue.toUpperCase().replace(/[^a-zA-Z0-9]/g, '');
        const priceDetailsRes = await standardBizApiClient.certification_get_market_price(certCode, formattedPromoCode);
        if (priceDetailsRes?.is_error) {
            setShowPromoCodeApplyLoader(false);
            if (priceDetailsRes?.code === "INVALID_PROMOCODE") {
                setErrorMessage("The promo code is invalid or expired.");
                return;
            }

            const isErrorHandled = _handlePromoCodeRateLimitError(priceDetailsRes);
            if (isErrorHandled) return;

            setErrorMessage("Error while applying promo code, please try again soon.");
            return;
        }
        const newPriceDetails = {
            ...priceDetailsRes,
            renewal_display_text: `Every ${priceDetailsRes.renewal_months} Months`
        }
        setPriceDetails(newPriceDetails);
        setPromoCodeApplied(formattedPromoCode);
        setShowPromoCodeApplyLoader(false);
    }

    // returns true when the error was handled
    // returns false when it wasn't a rate limit error
    const _handlePromoCodeRateLimitError = (res) => {
        const isRateLimit = res?.code === 'RATE_LIMIT' && res?.context_code === 'PROMOCODE';
        if (isRateLimit == false) return false;

        let rateLimitSecondsRemaining = null;
        const kvDetail = res?.kvdetail || [];
        kvDetail.forEach(kvDetailObj => {
            if (kvDetailObj?.key === 'limit_seconds_remaining') {
                rateLimitSecondsRemaining = kvDetailObj?.value;
            }
        });

        let timeSentence = 'Please try again soon.'; // default if there is no time given
        if (rateLimitSecondsRemaining) {
            timeSentence = `Please try again in ${getSentenceTimeFromSeconds(rateLimitSecondsRemaining, true).sentence}.`;
        }
        setErrorMessage(`You've reached the maximum number of promo code attempts. ${timeSentence}`);
        return true;
    }

    const handlePromoCodeClear = async () => {
        setShowPromoCodeClearLoader(true);
        setErrorMessage(null);

        const priceDetailsRes = await standardBizApiClient.certification_get_market_price(certCode);
        if (priceDetailsRes?.is_error) {
            setShowPromoCodeClearLoader(false);
            setErrorMessage("Error while clearing promo code, please try again soon.");
            return;
        }
        const newPriceDetails = {
            ...priceDetailsRes,
            renewal_display_text: `Every ${priceDetailsRes.renewal_months} Months`
        }
        setPriceDetails(newPriceDetails);
        setPromoCodeApplied("");
        setPromoCodeInputValue("");
        setShowPromoCodeClearLoader(false);
    }

    if (showSkeletonLoader) {
        return (
            <>
                {errorMessage ? <Alert severity="error">{errorMessage}</Alert> : null}
                <CertSubscribeSkeleton certCode={certCode} certName={certName} onCancel={onCancel}/>
            </>
        );
    }

    if (showSuccessPage) {
        return <CertSubscribeSuccess />
    }

    return (
        <div className="cert-subscribe">                
            {errorMessage ? <Alert severity="error" style={{ marginBottom: "20px" }}>{errorMessage}</Alert> : null}



            {/* NOTE:
            
                Please completely rewrite this later. dont have time to do it properly right now
            
            */}
            <div className="cert-subscribe-price-container">
                <div 
                    className="cert-subscribe-price-cert-name font-header-bold"
                    style={{ borderColor: certColors?.color, color: certColors?.color }}
                >
                    {certName}
                </div>
                <div className="cert-subscribe-price-content">
                    <div className="cert-subscribe-price-top-text-container">
                        {/* if a promo code is applied, show the normal price slashed out */}
                        {priceDetails?.promocode ? (
                            <p className="cert-subscribe-price-original font-header-light">
                                <span>$</span>
                                <span className="cert-subscribe-price-original-number">{priceDetails.price}</span>
                            </p>
                        ) : null}
                        
                        <p className="cert-subscribe-price-active font-header-light">
                            <span>$</span>
                            {priceDetails?.promocode ? priceDetails.adjusted_price : priceDetails.price}
                        </p>
                        
                        <p className="cert-subscribe-price-interval">
                            <span>/</span>{priceDetails.renewal_display_text}
                        </p>
                    </div>       
                    {priceDetails?.promocode && priceDetails?.promocode?.name ? (
                        <p className="cert-subscribe-price-promo-name">
                            <strong>Promo:</strong> {priceDetails.promocode.name}
                        </p>
                    ) : null}         
                </div>
            </div>

            <div className="cert-subscribe-feature-list">
                <div className="cert-subscribe-feature-item">
                    <div className="cert-subscribe-feature-icon">
                        <CheckCircleIcon />
                    </div>
                    <p>Unlimited Domain-Specific Practice Tests</p>
                </div>
                <div className="cert-subscribe-feature-item">                    
                    <div className="cert-subscribe-feature-icon">
                        <CheckCircleIcon />
                    </div>
                    <p>Unlimited Timed Full Practice Tests</p>
                </div>
            </div>

            <hr className="cert-subscribe-hr" />

            <form onSubmit={handlePromoCodeSubmit} className="cert-subscribe-promo-code-form">
                <div className="cert-subscribe-promo-code-form-row">
                    <TextField 
                        disabled={showPromoCodeApplyLoader || showSubscribeLoader}
                        value={promoCodeInputValue}
                        onChange={e => {
                            const newPromoCodeInputValue = e.target.value.toUpperCase().replace(/[^a-zA-Z0-9]/g, '')
                            setPromoCodeInputValue(newPromoCodeInputValue)
                        }}
                        label="Promo Code"
                    />
                    <LoadingButton
                        variant="contained"
                        customProps={{
                            disabled: !promoCodeInputValue || showSubscribeLoader,
                            loading: showPromoCodeApplyLoader,
                            onClick: handlePromoCodeSubmit
                        }}
                    >
                        Apply
                    </LoadingButton>
                </div>
                {promoCodeApplied ? (
                    <LoadingButton
                        variant="text"
                        customProps={{
                            loading: showPromoCodeClearLoader,
                            onClick: handlePromoCodeClear
                        }}
                    >
                        Clear Promo Code
                    </LoadingButton>
                ) : null}
            </form>

            {showCardForm === true ? (
                <form onSubmit={handleSubscribe} className="cert-subscribe-payment-method-form">
                    <p className="cert-subscribe-payment-method-form-label font-header-regular">
                        Payment Card Details
                    </p>
                    {/* 
                        if the user is changing their card,
                        then explain what changing their card does, and give a button to cancel changing it
                    */}
                    {cardDetails?.detail ? (
                        <Alert severity="info" style={{ marginBottom: "10px" }}>
                            After your payment card changes, 
                            all your active subscriptions will be billed to the new card starting on the next billing cycle.
                            <br />
                            <br />
                            <Button                
                                variant="text"
                                customProps={{
                                    disabled: showSubscribeLoader,
                                    onClick: () => setShowCardForm(false)
                                }}
                            >
                                Cancel Changing Payment Card
                            </Button>
                        </Alert>
                    ) : null}
                    <StyledStripeCardElement onChange={state => setIsCardFormComplete(state.complete)} />
                </form>
            ) : null}

            {showCardForm === false ? (
                <div className="cert-subscribe-change-payment-method-container">
                    {cardDetails?.detail ? (  
                        <div className="cert-subscribe-change-payment-method-card">
                            {/* Card Brands: amex, diners, discover, jcb, mastercard, unionpay, visa, unknown */}
                            <Image src={`/icon/card/${cardDetails.detail.brand}.png`} alt={cardDetails.detail.brand} />
                            <div>
                                <p>****&nbsp;{cardDetails.detail.last4}</p>
                                <p className="cert-subscribe-change-payment-method-card-exp">
                                    {/* Enforce 2 digit exp month, leading 0 if less than 10 */}
                                    {parseInt(cardDetails.detail.exp_month) < 10 ? `0${cardDetails.detail.exp_month}` : cardDetails.detail.exp_month}/
                                    {cardDetails.detail.exp_year}
                                </p>
                            </div>
                        </div>
                    ) : null}
                    <Button                
                        variant="text"
                        customProps={{
                            disabled: showSubscribeLoader,
                            onClick: () => setShowCardForm(true)
                        }}
                    >
                        Change Payment Card
                    </Button>
                </div>
            ) : null}

            <div className="cert-subscribe-payment-action-buttons">
                <LoadingButton 
                    variant="contained"
                    customProps={{
                        disabled: showPromoCodeApplyLoader || (showCardForm && !isCardFormComplete),
                        loading: showSubscribeLoader,
                        onClick: handleSubscribe
                    }}
                >
                    Subscribe
                </LoadingButton>
                <Button                
                    variant="outlined"
                    customProps={{
                        disabled: showSubscribeLoader,
                        onClick: onCancel
                    }}
                >
                    Back
                </Button>                
            </div>
        </div>
    )
}


export default CertSubscribeInner;
