import React, { useState } from "react";
import TextField from "../../components/customMui/TextField";
import LoadingButton from "../../components/customMui/LoadingButton";
import Alert from "../../components/customMui/Alert";
import standardBizApiClient from "../../util/BizApi/standardBizApiClient";
import Check from '@mui/icons-material/Done';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import "./signupForm.css";

const SignupForm = () => {
    const [submitLoading, setSubmitLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);
    const [emailAddress, setEmailAddress] = useState("");
    const [uppercaseValidation, setUppercaseValidation] = useState(false);
    const [lowercaseValidation, setLowercaseValidation] = useState(false);
    const [digitValidation, setDigitValidation] = useState(false);
    const [specialCharValidation, setSpecialCharValidation] = useState(false);
    const [lengthValidation, setLengthValidation] = useState(false);
    const [passwordValidated, setPasswordValidated] = useState(false);
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [showSuccess, setShowSuccess] = useState(false);

    const handlePasswordValidation = (value) => {
        let oneUppercaseValidation = /^(?=.*[A-Z])/.test(value)
        let oneLowercaseValidation = /^(?=.*[a-z])/.test(value)
        let oneDigitValidation = /^(?=.*[0-9])/.test(value)
        let oneSpecialCharValidation = /^(?=.*[^a-zA-Z0-9\s])/.test(value)
        let minLengthValidation = value.length > 7

        setUppercaseValidation(oneUppercaseValidation)
        setLowercaseValidation(oneLowercaseValidation)
        setDigitValidation(oneDigitValidation)
        setSpecialCharValidation(oneSpecialCharValidation)
        setLengthValidation(minLengthValidation)
        if (oneUppercaseValidation && oneLowercaseValidation && oneDigitValidation && oneSpecialCharValidation && minLengthValidation) {
            setPasswordValidated(true)
        } else {
            setPasswordValidated(false)
        }
        setPassword(value)
    }

    const handleSignup = async e => {
        e.preventDefault();

        setErrorMessage(null)
        if (password !== confirmPassword) {
            setErrorMessage("Passwords do not match")
            return;
        }
        setSubmitLoading(true);
        const signupRes = await standardBizApiClient.account_signup(emailAddress, password);
        if (signupRes?.is_error) {
            setErrorMessage("Error while signing up, please try again soon.");
            setSubmitLoading(false);
            return;
        }
        setShowSuccess(true);
        setSubmitLoading(false);
    }


    if (showSuccess) {
        return (
            <div className="signup signup-success">
                <h2 className="font-header-light">Thank you!</h2>
                <p>
                    WannaPractice Support (support@wannapractice.com) is sending you an email to verify <strong>{emailAddress}</strong>.
                </p>
                <p>
                    Please follow the instructions in that email.
                </p>
            </div>
        )
    }

    return (
        <form className="signup-form" onSubmit={handleSignup} >
            {errorMessage ? (<Alert severity="error">{errorMessage}</Alert>) : null}
            <TextField
                required
                disabled={submitLoading}
                value={emailAddress}
                onChange={e => setEmailAddress(e.target.value)}
                id="signup-email-address"
                type="email"
                label="Email Address"
                autoComplete="new-password"
            />
            <TextField
                required
                disabled={submitLoading}
                value={password}
                onChange={e => handlePasswordValidation(e.target.value)}
                id="signup-password"
                type="password"
                label="Password"
                autoComplete="new-password"
            />
            <TextField
                required
                disabled={submitLoading || !passwordValidated}
                value={confirmPassword}
                onChange={e => setConfirmPassword(e.target.value)}
                id="signup-confirm-password"
                type="password"
                label="Retype Password"
                autoComplete="new-password"
            />
            <div className="password-requirements">
                <p>Minimum password requirements:</p>
                {/* also add a view password eye icon to the password inputs */}
                <ul>
                    <li>{lengthValidation ? <Check fontSize="small" color="success" /> : <HighlightOffIcon fontSize="small" color="disabled" />}<span className={`pass-validation-${lengthValidation}`}>8 characters long</span> </li>
                    <li>{uppercaseValidation ? <Check fontSize="small" color="success" /> : <HighlightOffIcon fontSize="small" color="disabled" />}<span className={`pass-validation-${uppercaseValidation}`}> 1 uppercase letter</span></li>
                    <li>{lowercaseValidation ? <Check fontSize="small" color="success" /> : <HighlightOffIcon fontSize="small" color="disabled" />}<span className={`pass-validation-${lowercaseValidation}`}> 1 lowercase letter</span> </li>
                    <li>{digitValidation ? <Check fontSize="small" color="success" /> : <HighlightOffIcon fontSize="small" color="disabled" />}<span className={`pass-validation-${digitValidation}`}>1 number </span></li>
                    <li>{specialCharValidation ? <Check fontSize="small" color="success" /> : <HighlightOffIcon fontSize="small" color="disabled" />}<span className={`pass-validation-${specialCharValidation}`}>1 special character</span> </li>
                </ul>
            </div>
            <p className="terms-and-policy">By clicking on "Sign Up", you agree to WannaPractice's <a href="https://www.wannapractice.com/terms-of-use/">Terms Of Use</a>, including our <a href="https://www.wannapractice.com/privacy-policy/">Privacy Policy</a></p>
            <LoadingButton customProps={{ type: "submit", loading: submitLoading }}>Sign Up</LoadingButton>
        </form>
    )
}

export default SignupForm;