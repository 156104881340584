import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, Link } from "react-router-dom";
import { setCertCodeInFocus, setCertCodeSubscribedList, setCertList, setSelectedPracticeTab } from "../../redux/slices/practiceSlice";
import PracticeDomains from "./practiceDomains/PracticeDomains";
import PracticeFull from "./practiceFull/PracticeFull";
import PracticeExamAlreadyInProgress from "./practiceExamAlreadyInProgress/PracticeExamAlreadyInProgress";
import PageWrapper from "../../components/pageWrapper/PageWrapper";
import Alert from "../../components/customMui/Alert";
import { Tabs, Tab } from "@mui/material";
import { styled } from "@mui/material/styles";
import standardBizApiClient from "../../util/BizApi/standardBizApiClient";
import pagePath from "../../util/constants/pagePath";
import getCssVariable from "../../util/functions/getCssVariable";
import getCertColors from "../../util/functions/getCertColors";
import "./practice.css";

const Practice = () => {
    const { isExamInProgress } = useSelector((state) => state.exam);
    const { certList, certCodeSubscribedList, certCodeInFocus, selectedPracticeTab } = useSelector((state) => state.practice);
    const [pageRouteLoading, setPageRouteLoading] = useState(true);
    const [certInFocus, setCertInFocus] = useState(null);
    const [certDetail, setCertDetail] = useState(null);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    // only the certList useEffect may be needed, havent done enough testing yet
    useEffect(() => {
        handleCertCases();
        refreshCerts();
    }, []);

    useEffect(() => {
        handleCertCases();
        formatCertInFocus();
    }, [certList]);

    useEffect(() => {
        formatCertInFocus();
    }, [certCodeInFocus]);

    const refreshCerts = async () => {
        const certListRes = await standardBizApiClient.certification_list();
        if (certListRes?.is_error) {
            return;
        }
        const { cert_code_in_focus, cert_code_subscribed_list, cert_list } = certListRes.certs;
        dispatch(setCertCodeInFocus(cert_code_in_focus));
        dispatch(setCertCodeSubscribedList(cert_code_subscribed_list));
        dispatch(setCertList(cert_list));
    }

    const handleCertDetail = async (cert) => {
        const certDetailRes = await standardBizApiClient.certification_detail(cert)
        setCertDetail(certDetailRes.description)
    }


    const formatCertInFocus = () => {
        let newCertInFocus = certList.filter(cert => cert.cert.code === certCodeInFocus);
        if (newCertInFocus.length === 0) return;
        newCertInFocus = {
            ...newCertInFocus[0],
            color: getCertColors(newCertInFocus[0].cert.code)
        };
        setCertInFocus(newCertInFocus);

        handleCertDetail(newCertInFocus.cert.code)
    }

    const handleCertCases = async () => {
        // if (isExamInProgress) {
        //     navigate(pagePath.practice_test);
        //     return;
        // }
        // if user has no subscriptions, redirect them to a buy subscription page
        if (certCodeSubscribedList.length === 0) {
            return navigate(pagePath.practice_no_subscribed_certs);
        }
        // else show the user the practice dashboard
        setPageRouteLoading(false);
    }





    if (pageRouteLoading) {
        return (
            <div className="practice">
                {/* this page loader should only be used while we are finding the route of the page to go to */}
                {/* maybe put a spinner here */}
                {/* in general a skeleton should be used, but not here in this edge case */}
            </div>
        )
    }


    // FUTURE NOTE:
    // this styled tabs needs to go in a reusable component
    // dont have the time right now
    const StyledTabs = styled((props) => (
        <Tabs
            {...props}
            TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
        />
    ))({
        // "& .MuiTabs-root": {
        //     width: "100%"
        // },
        "& .MuiTabs-indicator": {
            display: "flex",
            justifyContent: "center",
            // backgroundColor: "transparent",
        },
        "& .MuiTabs-indicatorSpan": {
            // maxWidth: 40,
            width: "100%",
            backgroundColor: certInFocus?.color?.color ? certInFocus.color.color : getCssVariable("--wnp-color-primary"),
        },
    });

    const StyledTab = styled((props) => <Tab {...props} />)(
        ({ theme }) => ({
            textTransform: "none",
            fontFamily: getCssVariable("--wnp-font-header-regular"),
            fontWeight: "400",
            fontSize: "18px",
            // fontSize: theme.typography.pxToRem(15),
            marginRight: theme.spacing(1),
            color: certInFocus?.color?.color ? certInFocus.color.color : getCssVariable("--wnp-color-primary"),
            "&.Mui-selected": {
                color: certInFocus?.color?.color ? certInFocus.color.color : getCssVariable("--wnp-color-primary"),
            },
            "&.Mui-focusVisible": {
                backgroundColor: certInFocus?.color?.color ? certInFocus.color.color : getCssVariable("--wnp-color-primary"),
            },
        }),
    );

    // const primaryColor = getCssVariable("--wnp-color-primary");

    return (
        <PageWrapper className="practice">
            {/* 
                future note
                    these tabs should only be used on mobile, 
                    desktop should just show all the tabs as widgets or something
            */}

            <PracticeExamAlreadyInProgress />

            <StyledTabs
                variant="fullWidth"
                value={selectedPracticeTab}
                onChange={(e, value) => { dispatch(setSelectedPracticeTab(value)) }}
            >
                <StyledTab value="info" label="Info" />
                <StyledTab value="domains" label="Domains" />
                <StyledTab value="full_test" label={(<>Full&nbsp;Test</>)} />
            </StyledTabs>

            <div className="practice-tab-content">
                {selectedPracticeTab === "info" ? (
                    <div style={{ width: "100%" }}>
                        <div className="practice-domains-description">
                            <Alert severity="info" >{certDetail}</Alert>
                        </div>    
                        <div style={{ width: "100%", textAlign: "center" }}>
                            <Link to={pagePath.account}>
                                Manage {certInFocus?.cert?.name ? certInFocus.cert.name : null} Subscription
                            </Link>
                        </div>                    
                    </div>
                ) : null}

                {selectedPracticeTab === "domains" ? (
                    <PracticeDomains />
                ) : null}

                {selectedPracticeTab === "full_test" ? (
                    <PracticeFull />
                ) : null}
            </div>
        </PageWrapper>
    )
}

export default Practice;