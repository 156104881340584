import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setSelectedNumberOfQuestions } from "../../../redux/slices/practiceSlice";
import PracticeDomainHistory from "./practiceDomainHistory/PracticeDomainHistory";
import Alert from "../../../components/customMui/Alert";
import Button from "../../../components/customMui/Button";
import LoadingButton from "../../../components/customMui/LoadingButton";
import Modal from "../../../components/customMui/modal/Modal";
import Select from "../../../components/customMui/Select";
import LinearProgress from "../../../components/customMui/LinearProgress";
import CircularProgress from "../../../components/customMui/CircularProgress";
import { MenuItem } from "@mui/material";
import standardBizApiClient from "../../../util/BizApi/standardBizApiClient";
import pagePath from "../../../util/constants/pagePath";
import getCssVariable from "../../../util/functions/getCssVariable";
import getCertColors from "../../../util/functions/getCertColors";
import "./practiceDomains.css";

const PracticeDomains = () => {
    const { certList, certCodeInFocus, selectedNumberOfQuestions } = useSelector((state) => state.practice);
    const [showPageLoader, setShowPageLoader] = useState(true);
    const [formattedCert, setFormattedCert] = useState(null);
    const [practiceFormLoading, setPracticeFormLoading] = useState(false);
    const [practiceFormDomainId, setPracticeFormDomainId] = useState(null);
    const [practiceFormError, setPracticeFormError] = useState(null);
    const [practiceFormDomainName, setPracticeFormDomainName] = useState("");
    const [testHistoryDomain, setTestHistoryDomain] = useState(null);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    useEffect(() => {
        handleFormatCert();
    }, [certList, certCodeInFocus]);

    const handleFormatCert = () => {
        let newFormattedCert = certList.filter(cert => cert.cert.code === certCodeInFocus);
        if (newFormattedCert.length === 0) {
            setShowPageLoader(false);
            return;
        }
        newFormattedCert = {
            ...newFormattedCert[0],
            color: getCertColors(newFormattedCert[0].cert.code)
        };
        setFormattedCert(newFormattedCert);
        setShowPageLoader(false);
    }

    const handleShowPracticeForm = (domainId, domainName) => {
        setPracticeFormDomainId(domainId);
        setPracticeFormDomainName(domainName);
    }

    const handleHidePracticeForm = () => {
        // prevent user closing modal while test is being created
        if (practiceFormLoading) return;
        setPracticeFormDomainId(null);
        setPracticeFormDomainName("");
    }

    const handleStartPracticeExam = async () => {
        if (practiceFormLoading) return;
        setPracticeFormLoading(true);
        setPracticeFormError(null);
        const createPracticeExamRes = await standardBizApiClient.exam_create_domain_practice(practiceFormDomainId, selectedNumberOfQuestions);
        if (createPracticeExamRes?.is_error) {
            setPracticeFormError("Error while creating practice test, please try again soon.");
            setPracticeFormLoading(false);
            return;
        }
        navigate(pagePath.practice_test);
    }

    const handleShowTestHistory = (domain) => {
        setTestHistoryDomain(domain);
    }

    const handleHideTestHistory = () => {
        setTestHistoryDomain(null);
    }

    if (showPageLoader) {
        return null;
    }

    if (!formattedCert || formattedCert?.domains?.length === 0) {
        return (
            <Alert severity="info">
                There are no domain-specific practice tests for {formattedCert?.cert?.name ? formattedCert.cert.name : null}, please check back later.
            </Alert>
        )
    }

    return (
        <div>
            <Modal
                title={<h5>Practice Test</h5>}
                subTitle={<p>{practiceFormDomainName}</p>}
                onCloseIconClick={handleHidePracticeForm}
                customProps={{
                    open: practiceFormDomainId !== null,
                    onClose: handleHidePracticeForm
                }}
            >
                {practiceFormError ? <Alert severity="error">{practiceFormError}</Alert> : null}
                {/* <p>Start practice test{practiceFormDomainName ? " for " + practiceFormDomainName : ""}.</p> */}
                <div className="practice-domains-pratice-test-number">
                    <p>Number of Questions:</p>
                    <Select
                        // label="Number of Questions"
                        // labelId="number-of-question-input-label"
                        customProps={{
                            value: selectedNumberOfQuestions || 10,
                            onChange: e => dispatch(setSelectedNumberOfQuestions(e.target.value)),
                            disabled: practiceFormLoading,
                            size: "small",
                            sx: { minWidth: "75px" }
                        }}
                    >
                        <MenuItem value={5}>5</MenuItem>
                        <MenuItem value={10}>10</MenuItem>
                        <MenuItem value={25}>25</MenuItem>
                        <MenuItem value={50}>50</MenuItem>
                        <MenuItem value={100}>100</MenuItem>
                    </Select>
                </div>
                <div className="practice-domains-pratice-test-buttons">
                    <Button
                        variant="outlined"
                        customProps={{
                            onClick: handleHidePracticeForm,
                            disabled: practiceFormLoading
                        }}
                    >
                        Cancel
                    </Button>
                    <LoadingButton
                        customProps={{
                            onClick: handleStartPracticeExam,
                            loading: practiceFormLoading
                        }}
                    >
                        Start
                    </LoadingButton>
                </div>
            </Modal>

            <Modal
                title={<h5>Test History</h5>}
                subTitle={<p>{testHistoryDomain?.domain?.name}</p>}
                onCloseIconClick={handleHideTestHistory}
                customProps={{
                    open: testHistoryDomain !== null,
                    onClose: handleHideTestHistory
                }}
            >
                <PracticeDomainHistory domain={testHistoryDomain} />
            </Modal>
            <div className="practice-domains-description">
                <Alert severity="info">
                    Practice questions with no time limit; 
                    results and explanation will be shown after each question. 
                    Choose which Domain you want to study, and how many questions. 
                    To quit, click the "Cancel" button at the top.
                </Alert>
            </div>
            <div className="practice-domains-list">
                {formattedCert?.domains && formattedCert.domains.map((domain, index) => {
                    return (
                        <div key={index}>
                            <div className="practice-domains-list-item">
                                <div className="practice-domains-list-main-content">
                                    <h5 className="font-header-light">{domain?.domain?.name ? domain.domain.name : "Domain"}</h5>
                                    <div className="practice-domains-list-progress-container">
                                        <p className="practice-domains-list-progress-label">{Math.floor(domain.assoc.coverage_factor * 100)}% Covered</p>
                                        <LinearProgress 
                                            value={Math.floor(domain.assoc.coverage_factor * 100)}
                                            min={0} 
                                            max={100} 
                                            size={6} 
                                            color={formattedCert.color.color}
                                            // style={}
                                        />
                                    </div>
                                    <div className="practice-domains-list-button-container">
                                        <Button
                                            variant="contained"
                                            customProps={{
                                                onClick: () => handleShowPracticeForm(domain.domain.cert_domain_id, domain.domain.name),
                                                size: "small",
                                                sx: { marginRight: "10px" }
                                            }}
                                        >
                                            Practice Test
                                        </Button>
                                        {domain.test_history.length > 0 ? (
                                            <Button
                                                variant="outlined"
                                                customProps={{
                                                    onClick: () => handleShowTestHistory(domain),
                                                    size: "small"
                                                }}
                                            >
                                                History
                                            </Button>
                                        ) : null}
                                    </div>
                                </div>
                                <div className="practice-domains-list-score-content">
                                    <p className="practice-domains-list-score-label">Avg. Score</p>
                                    <CircularProgress
                                        value={Math.floor(domain.assoc.score_factor * 100)}
                                        min={0}
                                        max={100}
                                        size={60}
                                        thickness={4}
                                        color={domain.assoc.score_factor >= formattedCert.full_test.passing_score_factor ? getCssVariable("--wnp-color-correct") : getCssVariable("--wnp-color-incorrect")}
                                        backgroundColor={null}
                                        label={Math.floor(domain.assoc.score_factor * 100) + "%"}
                                    />
                                    <p style={{ marginTop: "5px" }}>
                                        {domain.assoc.domain_practice_exam_count} {domain.assoc.domain_practice_exam_count === 1 ? "Test" : "Tests"}
                                    </p>
                                </div>
                            </div>
                            {formattedCert.domains.length - 1 > index ? <hr className="practice-domains-list-hr" /> : null}
                        </div>
                    )
                })}
            </div>
        </div>
    )
}

export default PracticeDomains;