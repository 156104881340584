import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Button from "../../../../components/customMui/Button";
import CircularProgress from "../../../../components/customMui/CircularProgress";
import PracticeHistoryQuestions from "../../practiceHistoryQuestions/PracticeHistoryQuestions";
import getCssVariable from "../../../../util/functions/getCssVariable";
import pagePath from "../../../../util/constants/pagePath";
import "./practiceTestResult.css";

const PracticeTestResult = ({ examSummary }) => {
    const { certList, certCodeInFocus } = useSelector((state) => state.practice);
    const [ passingScoreFactor, setPassingScoreFactor ] = useState(0.7);

    useEffect(() => {
        handleFormatCert();
    }, [certList, certCodeInFocus]);

    const handleFormatCert = () => {
        let certInFocus = certList.filter(cert => cert.cert.code === certCodeInFocus);
        if (certInFocus.length === 0) {
            return;
        }
        certInFocus = certInFocus[0];
        setPassingScoreFactor(certInFocus.full_test.passing_score_factor);
    }

    return (
        <div>
            <CircularProgress 
                value={examSummary?.counts?.peq_answered_pass} 
                min={0}
                max={examSummary?.counts?.peq_total}
                size={150}
                thickness={5}
                color={examSummary?.counts?.score_factor >= passingScoreFactor ? getCssVariable("--wnp-color-correct") : getCssVariable("--wnp-color-incorrect")}
                label={(                            
                    <h3>
                        {examSummary?.counts?.score_factor ? (
                            Math.floor(examSummary.counts.score_factor * 100) + "%"
                        ) : "0%" }
                    </h3>
                )} 
                style={{ marginTop: "20px" }}
            />

            {examSummary?.counts?.peq_answered_pass < examSummary?.counts?.peq_total ? (
                <p className="practice-test-result-message">
                    {examSummary.counts.peq_answered_pass}&nbsp;
                    /&nbsp;
                    {examSummary.counts.peq_total}&nbsp;
                    Correct
                </p>
            ) : (
                <p className="practice-test-result-message">
                    Congratulations!<br/>
                    {examSummary.counts.peq_answered_pass}&nbsp;
                    /&nbsp;
                    {examSummary.counts.peq_total}&nbsp;
                    Correct
                </p>
            )}

            <Link to={pagePath.practice}>
                <Button customProps={{ sx: { width: "100%", margin: "10px 0"} }}>
                    Continue to Practice Dashboard
                </Button>
            </Link>

            <PracticeHistoryQuestions 
                examSummary={examSummary}
                // improve scroll later, cant use alignToTop unless topbar/layout styles are changed. layout children would need to be a scrollable box under the topbar
                autoScrollOptions={{ alignToTop: false, options: { behavior: "smooth" } }} 
            />

            <Link to={pagePath.practice}>
                <Button customProps={{ sx: { width: "100%", margin: "10px 0"} }}>
                    Continue to Practice Dashboard
                </Button>
            </Link>
        </div>
    )
}

export default PracticeTestResult;