import React, { useEffect, useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { setIsExamInProgress, setLatestExamType, setLatestExamId, setPingIntervalSeconds, setTimeRemainingSeconds } from "../../../redux/slices/examSlice";
import PracticeQuestionAnswerDomain from "./practiceQuestionAnswer/PracticeQuestionAnswerDomain";
import PracticeQuestionAnswerFull from "./practiceQuestionAnswer/PracticeQuestionAnswerFull";
import PracticeTestResult from "./practiceTestResult/PracticeTestResult";
import PracticePing from "./practicePing/PracticePing";
import PracticeTimer from "./practiceTimer/PracticeTimer";
import PageWrapper from "../../../components/pageWrapper/PageWrapper";
import LoadingButton from "../../../components/customMui/LoadingButton";
import Alert from "../../../components/customMui/Alert";
import standardBizApiClient from "../../../util/BizApi/standardBizApiClient";
import pagePath from "../../../util/constants/pagePath";
import { getClockTimeFromSeconds } from "../../../util/functions/time";
import Button from "../../../components/customMui/Button";
import LinearProgress from "../../../components/customMui/LinearProgress";
import { Popover, Skeleton } from "@mui/material";
import "./practiceTest.css";

const PracticeTest = () => {
    const { isExamInProgress, latestExamId, latestExamType, timeRemainingSeconds } = useSelector((state) => state.exam);
    const [pageLoader, setPageLoader] = useState(true);
    const [errorMessage, setErrorMessage] = useState(null);
    const [cancelLoader, setCancelLoader] = useState(false);
    const [cancelPopoverAnchorEl, setCancelPopoverAnchorEl] = useState(null);
    const [currentQuestion, setCurrentQuestion] = useState(null);
    const [currentQuestionNumber, setCurrentQuestionNumber] = useState(null);
    const [maxQuestionNumber, setMaxQuestionNumber] = useState(null);
    const [showTestResults, setShowTestResults] = useState(false);
    const [examSummary, setExamSummary] = useState(null);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    useEffect(() => {
        handlePageLoad();
    }, []);

    useEffect(() => {
        // for timed tests, isExamInProgress will be set to false when the test expires
        // so end the exam and show the test results in that case
        if (latestExamType === "full" && timeRemainingSeconds <= 0 && latestExamId) {
            if (isExamInProgress === false && showTestResults === false) {
                handleShowTestResults();
            }
        }
    }, [isExamInProgress]);

    const handlePageLoad = async () => {
        setPageLoader(true);
        setErrorMessage(null);
        // ensure the user has an in progress exam
        const examMetaRes = await standardBizApiClient.exam_meta();
        if (examMetaRes?.is_error) {
            setPageLoader(false);
            setErrorMessage("Error while getting test details, please try again soon");
            return;
        }
        const { exam_type } = examMetaRes;
        if (Boolean(exam_type) === false) {
            navigate(pagePath.practice);
            dispatch(setIsExamInProgress(false));
            return;
        }

        const {
            peq_answered,
            peq_total,
            practice_exam_id,
            ping_interval_seconds,
            test_time_limit_seconds,
            established_time_seconds
        } = examMetaRes;
        const newCurrentQuestionNumber = peq_answered + 1;

        if (exam_type === "full") {
            const newTimeRemainingSeconds = test_time_limit_seconds - established_time_seconds;
            dispatch(setTimeRemainingSeconds(newTimeRemainingSeconds));
        }

        dispatch(setLatestExamId(practice_exam_id))
        setCurrentQuestionNumber(newCurrentQuestionNumber);
        setMaxQuestionNumber(peq_total);
        dispatch(setIsExamInProgress(true));
        dispatch(setLatestExamType(exam_type));
        dispatch(setPingIntervalSeconds(ping_interval_seconds));

        // show the current question
        await getNextQuestion(false);
        setPageLoader(false);
    }

    const getNextQuestion = async (shouldIterate = true) => {
        setErrorMessage(null);

        if (shouldIterate && currentQuestionNumber !== null && currentQuestionNumber === maxQuestionNumber) {
            await handleShowTestResults();
            return;
        }

        const nextQuestionRes = await standardBizApiClient.exam_next_question();
        if (nextQuestionRes?.is_error) {
            // if there is no next question (failsafe for current/max question number check)
            if (
                nextQuestionRes?.code === "NEXT_QUESTION_ERROR" &&
                (
                    nextQuestionRes?.context_code === "NO_PEQ_OPEN" ||
                    nextQuestionRes?.context_code === "NO_PRACTICE_EXAM"
                )
            ) {
                await handleShowTestResults();
                return;
            }
            setErrorMessage("Error while getting question details, please try again soon");
            return;
        }
        const newCurrentQuestion = nextQuestionRes.question;
        setCurrentQuestion(newCurrentQuestion);
        if (shouldIterate) {
            const newCurrentQuestionNumber = currentQuestionNumber + 1;
            setCurrentQuestionNumber(newCurrentQuestionNumber);
        }
        return newCurrentQuestion;
    }

    const handleAnswerSubmit = async (selection) => {
        setErrorMessage(null);
        const answerRes = await standardBizApiClient.exam_answer_question(selection, latestExamType);
        if (answerRes?.is_error) {
            // if the exam has expired, then end the exam and show results
            if (answerRes?.code === "ANSWER_ERROR" && answerRes?.context_code === "NO_USER_PRACTICE_EXAM_ID") {
                await handleShowTestResults();
                return;
            }
            setErrorMessage("Error while submitting answer, please try again soon");
            return;
        }
        const answerResult = answerRes.answer;
        return answerResult;
    }

    const handleCancelExam = async () => {
        setCancelLoader(true);
        setErrorMessage(null);
        const cancelRes = await standardBizApiClient.exam_discard();
        if (cancelRes?.is_error) {
            setErrorMessage("Error while cancelling test, please try again soon");
            setCancelLoader(false);
            return;
        }
        navigate(pagePath.practice);
        dispatch(setIsExamInProgress(false));
        dispatch(setLatestExamType(null));
    }

    const handleShowTestResults = async () => {
        setErrorMessage(null);
        const examSummaryRes = await standardBizApiClient.exam_summary(latestExamId);
        // dont show the test results page if there is an error getting the data
        if (examSummaryRes?.is_error) {
            navigate(pagePath.practice);
            dispatch(setIsExamInProgress(false));
            dispatch(setLatestExamId(null));
            dispatch(setTimeRemainingSeconds(0));
            return;
        }
        setExamSummary(examSummaryRes);
        setShowTestResults(true);
        dispatch(setIsExamInProgress(false));
        dispatch(setLatestExamId(null));
        dispatch(setTimeRemainingSeconds(0));
    }

    if (pageLoader) {
        return (
            <PageWrapper title="" className="practice-test">
                <Skeleton animation="wave" sx={{ width: "100%", marginTop: "10px" }} />
                <PracticeQuestionAnswerDomain
                    showPageLoader={true}
                />
            </PageWrapper>
        )
    }

    return (
        <PageWrapper className="practice-test" title={showTestResults ? "Test Results" : null}>

            {errorMessage ? <Alert severity="error">{errorMessage}</Alert> : null}

            {isExamInProgress ? (
                <>
                    {latestExamType === "full" ? (
                        <p className="practice-test-time-remaining">
                            <span className="font-header-regular">Time Remaining:</span>&nbsp;
                            <span className="practice-test-time-remaining-clock">{getClockTimeFromSeconds(timeRemainingSeconds).clock}</span>
                        </p>
                    ) : null}

                    <LinearProgress
                        value={currentQuestionNumber}
                        min={0}
                        max={maxQuestionNumber}
                        preLabel={(
                            <div onClick={(e) => { setCancelPopoverAnchorEl(e.currentTarget) }}>
                                <LoadingButton


                                    // probably change to text or outlined when doing styling
                                    // variant="outlined"
                                    // also left align it probably
                                    variant="text"

                                    customProps={{
                                        loading: cancelLoader,
                                        sx: {
                                            paddingLeft: "10px",
                                            paddingRight: "10px",
                                            marginLeft: "-10px",
                                            marginRight: "2px",
                                            color: "red"
                                        }
                                    }}
                                >
                                    Cancel
                                </LoadingButton>
                            </div>
                        )}
                        postLabel={(
                            <p style={{ minWidth: "fit-content", marginLeft: "10px" }}>{currentQuestionNumber} / {maxQuestionNumber}</p>
                        )}
                        style={{ marginBottom: "10px" }}
                    />

                    {latestExamType === "domain" ? (
                        <>
                            <PracticePing />
                            <PracticeQuestionAnswerDomain
                                questionAnswerData={currentQuestion}
                                isLastQuestion={currentQuestionNumber === maxQuestionNumber}
                                onAnswerSubmit={(selection) => handleAnswerSubmit(selection)}
                                onNextQuestion={getNextQuestion}
                                showPageLoader={false}
                            />
                        </>
                    ) : null}

                    {latestExamType === "full" ? (
                        <>
                            <PracticePing />
                            <PracticeTimer />
                            <PracticeQuestionAnswerFull
                                questionAnswerData={currentQuestion}
                                isLastQuestion={currentQuestionNumber === maxQuestionNumber}
                                onAnswerSubmit={(selection) => handleAnswerSubmit(selection)}
                                onNextQuestion={getNextQuestion}
                                showPageLoader={false}
                            />
                        </>
                    ) : null}

                </>
            ) : null}

            {showTestResults ? (
                <PracticeTestResult examSummary={examSummary} />
            ) : null}

            <Popover
                open={Boolean(cancelPopoverAnchorEl)}
                onClose={() => setCancelPopoverAnchorEl(null)}
                anchorEl={cancelPopoverAnchorEl}
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "center",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "center",
                }}
            >
                <div className="practice-test-cancel-popover">
                    <p>Cancel the practice test in progress?</p>
                    <div className="practice-test-cancel-popover-button-container">
                        <Button
                            variant="outlined"
                            customProps={{
                                onClick: () => setCancelPopoverAnchorEl(null)
                            }}
                        >
                            No
                        </Button>
                        <Button
                            variant="contained"
                            customProps={{
                                onClick: () => {
                                    handleCancelExam();
                                    setCancelPopoverAnchorEl(null);
                                }
                            }}
                        >
                            Yes
                        </Button>
                    </div>
                </div>
            </Popover>
        </PageWrapper>
    )
}

export default PracticeTest;
