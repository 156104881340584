import React from "react";

/* 
    returns an image element that uses the wnp-images service

    props
        src     string     the image file path, excluding root "i" directory (example: "/logo/wnp-logo-black.svg")
        custom   props      any other attributes to apply to the image element
*/

const Image = ({ src, ...custom }) => { 
    if (typeof src !== "string") return null;
    if (src.charAt(0) !== "/") src = "/" + src;
    
    return (
        <img
            src={`/i${src}`}
            {...custom}
        />
    )
}

export default Image;