import React from "react";
import MuiButton from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import getCssVariable from "../../util/functions/getCssVariable";


const Button = ({ 
        variant="contained", 
        buttonColor=getCssVariable("--wnp-color-primary"), 
        buttonColorSelected=getCssVariable("--wnp-color-primary-dark"), 
        buttonColorFilledBackground=getCssVariable("--wnp-color-primary"), 
        fontSize="16px",
        customProps, 
        children 
    }) => {

    const fontFamily = getCssVariable("--wnp-font-body-regular");
    let StyledMuiButton;
    let newVariant = variant;
    if (variant === "contained") {
        StyledMuiButton = styled(MuiButton)(({ theme }) => ({
            color: "#fff",
            backgroundColor: buttonColor,
            fontFamily: fontFamily,
            fontSize: fontSize,
            textTransform: "none",
            "&:hover": {
                backgroundColor: buttonColorSelected,
            },
        }));
    }
    if (variant === "outlined") {
        StyledMuiButton = styled(MuiButton)(({ theme }) => ({
            color: buttonColor,
            borderColor: buttonColor,
            fontFamily: fontFamily,
            fontSize: fontSize,
            textTransform: "none",
            "&:hover": {
                color: buttonColorSelected,
                borderColor: buttonColorSelected,
            },
        }));
    }
    if (variant === "filled") {
        newVariant = "outlined";
        StyledMuiButton = styled(MuiButton)(({ theme }) => ({
            color: buttonColor,
            backgroundColor: buttonColorFilledBackground,
            borderColor: buttonColor,
            fontFamily: fontFamily,
            fontSize: fontSize,
            textTransform: "none",
            "&:hover": {
                backgroundColor: buttonColorFilledBackground,
                color: buttonColorSelected,
                borderColor: buttonColorSelected,
            },
        }));
    }
    if (variant === "text") {
        StyledMuiButton = styled(MuiButton)(({ theme }) => ({
            color: buttonColor,
            fontFamily: fontFamily,
            fontSize: fontSize,
            textTransform: "none",
            "&:hover": {
                color: buttonColorSelected,
                borderColor: buttonColorSelected,
            },
        }));
    }

    return (
        <StyledMuiButton
            variant={newVariant}
            {...customProps}
        >
            {children}
        </StyledMuiButton>
    )
}

export default Button;