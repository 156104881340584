import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { setCertCodeInFocus, setCertList } from "../../../redux/slices/practiceSlice";
import Select from "../../customMui/Select";
import Button from "../../customMui/Button";
import { MenuItem, Modal, Popover } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import standardBizApiClient from "../../../util/BizApi/standardBizApiClient";
import getCertColors from "../../../util/functions/getCertColors";
import pagePath from "../../../util/constants/pagePath";
import "./certSelect.css";

const CertSelect = () => {
    const { isExamInProgress } = useSelector((state) => state.exam);
    const { certList, certCodeSubscribedList, certCodeInFocus } = useSelector((state) => state.practice);
    const [formattedCertList, setFormattedCertList] = useState([]);
    const [selectedCertCode, setSelectedCertCode] = useState(certCodeInFocus);
    const [certChangeLoading, setCertChangeLoading] = useState(false);
    const [certChangedPopoverAnchorEl, setCertChangedPopoverAnchorEl] = useState(null);
    const [certChangedPopoverCertName, setCertChangedPopoverCertName] = useState(null);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();
    let certChangedPopoverTimeout = null;

    useEffect(() => {
        createFormattedCertList();
        return () => {
            if (certChangedPopoverTimeout) {
                clearTimeout(certChangedPopoverTimeout);
            }
        }
    }, []);

    useEffect(() => {
        createFormattedCertList();
    }, [certList, certCodeSubscribedList]);

    useEffect(() => {
        if (selectedCertCode !== certCodeInFocus) {
            const dontShowPopoverPathnames = [
                pagePath.practice_no_subscribed_certs
            ];
            if (dontShowPopoverPathnames.includes(location.pathname)) {
                setSelectedCertCode(certCodeInFocus);
                return;
            }

            // display popover to show the the selected cert was changed (without the user changing the dropdown)
            const newCertChangedPopoverAnchorEl = document.getElementById("cert-select-menu-container");
            const selectedCert = certList.filter(cert => cert.cert.code === certCodeInFocus)[0];
            if (!selectedCert) return;
            const newCertChangedPopoverCertName = selectedCert.cert.name;
            setSelectedCertCode(certCodeInFocus);
            setCertChangedPopoverAnchorEl(newCertChangedPopoverAnchorEl);
            setCertChangedPopoverCertName(newCertChangedPopoverCertName);

            certChangedPopoverTimeout = setTimeout(() => {
                handleCertChangedPopoverClose();
            }, 4000);
        }
    }, [certCodeInFocus]);

    const handleCertChangedPopoverClose = () => {
        setCertChangedPopoverAnchorEl(null);
        setCertChangedPopoverCertName(null);
        clearTimeout(certChangedPopoverTimeout);
        certChangedPopoverTimeout = null;
    }

    const createFormattedCertList = () => {
        const newFormattedCertList = certList.map(cert => {
            return {
                ...cert,
                color: getCertColors(cert.cert.code).color
            }
        })
        setFormattedCertList(newFormattedCertList);
    }

    const handleCertSelectChange = async (newSelectedCertCode) => {

        // if user is not subscribed to the cert, redirect them to the cert subscribe page

        if (certCodeSubscribedList.includes(newSelectedCertCode) === false) {
            navigate(pagePath.account);
            return;
        }

        // send set focus request
        setCertChangeLoading(true);
        setSelectedCertCode(newSelectedCertCode);
        const setFocusRes = await standardBizApiClient.certification_set_focus(newSelectedCertCode);
        if (setFocusRes?.is_error) {
            // future note: if changing focus fails, this should probably get the cert list to make sure everything is in sync
            setCertChangeLoading(false);
            setSelectedCertCode(certCodeInFocus);
            return;
        }

        // change the cert in focus without sending a get /cert/list request
        let newCertList = structuredClone(certList);
        newCertList = newCertList.map(cert => {
            if (cert.cert.code === newSelectedCertCode) {
                cert.assoc.is_in_focus = true;
                return cert;
            }
            cert.assoc.is_in_focus = false;
            return cert;
        });
        dispatch(setCertCodeInFocus(newSelectedCertCode));
        dispatch(setCertList(newCertList));
        setCertChangeLoading(false);

        // needs improvement later
        // if the user is not on the subscribe success page, redirect to practice page
        if (
            location.pathname !== pagePath.practice_no_subscribed_certs && 
            location.pathname !== pagePath.account
        ) {
            navigate(pagePath.practice);
        }
    }

    if (
        (formattedCertList.length === 0 || certCodeSubscribedList.length === 0) ||
        isExamInProgress === true
    ) {
        return null;
    }

    const handleAlreadySelectedCert = (certCode) => {
        if (selectedCertCode === certCode) {
            navigate(pagePath.practice);
        }
    }

    return (
        <div>
            <Modal
                open={selectedCertCode === null && certCodeInFocus === null}
                onClose={null}
            >
                <div className="cert-select-modal">
                    <h4 className="font-header-light">Select a Certification</h4>
                    <p>
                        There is no certification selected at the moment, which one would you like to practice? <br /><br />
                        You are already subscribed to the {certCodeSubscribedList.length === 1 ? "option" : "options"} below.
                    </p>
                    <div>
                        {formattedCertList.map((cert, index) => {
                            const { is_subscribed } = cert.assoc;
                            const { name, year, code } = cert.cert;
                            if (!is_subscribed) return null;
                            const { color, color_dark, color_extralight } = getCertColors(code);
                            return (
                                <div key={index}>
                                    <Button
                                        variant="filled"
                                        buttonColor={color}
                                        buttonColorSelected={color_dark}
                                        buttonColorFilledBackground={color_extralight}
                                        fontSize="18px"
                                        customProps={{
                                            onClick: () => handleCertSelectChange(code)
                                        }}
                                    >
                                        {name}
                                    </Button>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </Modal>

            <div id="cert-select-menu-container">
                <Select

                    customProps={{
                        value: selectedCertCode || "",
                        onChange: e => handleCertSelectChange(e.target.value),
                        size: "small",
                        disabled: certChangeLoading,
                        sx: { minWidth: "70px" }
                    }}
                >
                    {formattedCertList.map((cert, index) => {
                        const { is_subscribed } = cert.assoc;
                        const { name,code } = cert.cert;

                        const { color } = cert;
                        return (
                            <MenuItem
                                key={index}
                                value={code}
                                onClick={() => handleAlreadySelectedCert(code)}
                            >
                                <div className="cert-select-menu-item" style={{ color: is_subscribed ? color : "grey" }}>
                                    <span>
                                        <span className="cert-select-menu-code font-body-bold">{name}</span>&nbsp;
                                    </span>
                                    {is_subscribed === false ? (
                                        <>
                                            &nbsp;
                                            <span className="cert-select-menu-add">
                                                Subscribe
                                            </span>
                                        </>
                                    ) : null}
                                </div>
                            </MenuItem>
                        )
                    })}
                </Select>
            </div>

            {/* Popover used when selected cert is automatically changed (without user changing dropdown) */}
            <Popover
                open={Boolean(certChangedPopoverAnchorEl)}
                onClose={handleCertChangedPopoverClose}
                anchorEl={certChangedPopoverAnchorEl}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "center",
                }}
            >
                <div className="cert-select-menu-popover-content">
                    <p>
                        Certification changed to&nbsp;
                        <span style={{ fontWeight: "bold", color: getCertColors(selectedCertCode).color }}>
                            {certChangedPopoverCertName}
                        </span>
                    </p>
                    <div
                        className="cert-select-menu-popover-close-icon"
                        onClick={handleCertChangedPopoverClose}
                    >
                        <CloseIcon />
                    </div>
                </div>
            </Popover>
        </div>
    )
}

export default CertSelect;