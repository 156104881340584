import React from "react";
import { useSelector } from "react-redux";
import Button from "../../../components/customMui/Button";
import Alert from "../../../components/customMui/Alert";
import getCertColors from "../../../util/functions/getCertColors";
import "./practiceSubFirstCertFlow.css";

// this component depends on the redux practice.certList being set
const PracticeSubFirstCertFlowCertList = ({
    onCertSelect = (cert) => null,
}) => {
    const { certList } = useSelector((state) => state.practice);

    return (
        <>
            {certList.length === 0 ? (
                <Alert severity="warning" style={{ marginTop: "15px" }}>
                    There are no available certifications to practice at the moment, please check back soon.
                </Alert> 
            ) : null}

            <div className="practice-subscribe-cert-button-list-container">
                {certList.map((cert, index) => {
                    const { code, name } = cert.cert;
                    const { color, color_dark, color_extralight } = getCertColors(code);
                    return (
                        <div key={index} className="practice-subscribe-cert-button-wrapper">
                            <Button
                                variant="filled"
                                buttonColor={color}
                                buttonColorSelected={color_dark}
                                buttonColorFilledBackground={color_extralight}
                                fontSize="18px"
                                customProps={{
                                    onClick: () => onCertSelect(cert)
                                }} 
                            >
                                {name}
                            </Button>
                        </div>                        
                    )
                })}
            </div>      
        </>
    )
}

export default PracticeSubFirstCertFlowCertList;