// path guidelines
//  each path should start with a slash, but never end with a slash

const pagePathConstant = {
    // unauthenticated user paths
    healthcheck: "",
    home: "/",
    login: "/login",
    support_login: "/support_login",
    signup: "/signup",
    signup_confirm: "/confirm_signup/:token",
    reset_password_initiate: "/reset_password",
    reset_password: "/reset_password/:token",
    unauthenticated_default: "/login",
    // authenticated user paths
    account: "/account",
    support:"/support",
    practice: "/practice",
    practice_test: "/practice/test",
    practice_no_subscribed_certs: "/practice/subscribe_cert",
    authenticated_default: "/practice",
    // do not change base_ma_redirect. it is used by the mobile app to determine when to launch the browser
    base_ma_redirect: "/mobile_app_action/browser_redirect", 
}

const pagePathDynamic = {
    // mobile app action
    ma_redirect_account: pagePathConstant.base_ma_redirect + pagePathConstant.account,
    ma_redirect_practice_sub_cert: pagePathConstant.base_ma_redirect + pagePathConstant.practice_no_subscribed_certs,
    ma_redirect_signup: pagePathConstant.base_ma_redirect + pagePathConstant.signup,
}

const pagePath = {
    ...pagePathConstant,
    ...pagePathDynamic,
}

export default pagePath;