import React from "react";
import "./pageWrapper.css";

const PageWrapper = ({ className="", style={}, title=null, subTitle=null, children }) => {
    return (
        <div className={`page-wrapper ${className}`} style={style}>
            {title ? (
                <h2 className="page-wrapper-title font-header-light">{title}</h2>
            ) : null}
            {subTitle ? (
                <p className="page-wrapper-subtitle">{subTitle}</p>
            ) : null}
            {children}
        </div>
    )
}

export default PageWrapper;