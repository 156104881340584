import React from "react";
import MuiLinearProgress, { linearProgressClasses }  from "@mui/material/LinearProgress";
import { styled } from "@mui/material/styles";
import getCssVariable from "../../util/functions/getCssVariable";

const LinearProgress = ({ value, min=0, max=100, size=10, color=null, preLabel=null, postLabel=null, style={} }) => {

    const primaryColor = getCssVariable("--wnp-color-primary");
    const secondaryExtralightColor = getCssVariable("--wnp-color-secondary-extralight");

    const StyledLinearProgress = styled(MuiLinearProgress)(({ theme }) => ({
        height: size,
        borderRadius: size / 2,
        width: "100%",
        [`&.${linearProgressClasses.colorPrimary}`]: {
          backgroundColor: secondaryExtralightColor,
        },
        [`& .${linearProgressClasses.bar}`]: {
          borderRadius: size / 2,
          backgroundColor: color ? color : primaryColor,
        },
    }));

    const normalizedValueFromRange = value => {
        return ((value - min) * 100) / (max - min);
    }

    return (
        <div style={{ display: "flex", alignItems: "center", width: "100%", ...style }}>
            {preLabel}
            <StyledLinearProgress variant="determinate" value={normalizedValueFromRange(value)} />
            {postLabel}
        </div>
    )
}

export default LinearProgress;