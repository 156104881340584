import React from "react";
import { useSelector } from "react-redux";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import Topbar from "./topbar/Topbar";
import CertSelect from "./certSelect/CertSelect";
import pagePath from "../../util/constants/pagePath";
import { handleSignout } from "../../util/functions/handleSignout";
import "./layout.css";

const Layout = ({ children }) => {
  const { isExamInProgress } = useSelector((state) => state.exam);
  const { signedIn } = useSelector((state) => state.user);
  const theme = createTheme({
  });

  // unauthenticated user layout
  if (signedIn === false) {
    return (
      <div className="layout">
        <Topbar
          menuContent={[
            {
              type: "link",
              link: {
                name: "Sign Up",
                path: pagePath.signup,
              },
            },
            {
              type: "link",
              link: {
                name: "Login",
                path: pagePath.login,
              },
            },
          ]}
        />
        {children}
      </div>
    );
  }

  // authenticated user layout

  return (
    <ThemeProvider theme={theme}>
      <div className="layout">
        <Topbar
          logoSize="small"
          topbarMiddleContent={<CertSelect />}
          menuContent={[
            {
              type: "link",
              isHidden: isExamInProgress === true,
              link: {
                name: "Practice",
                path: pagePath.practice,
              },
            },
            {
              type: "link",
              isHidden: isExamInProgress === false,
              link: {
                name: "Practice Test",
                path: pagePath.practice_test,
              },
            },
            {
              type: "link",
              link: {
                name: "Account",
                path: pagePath.account,
              },
            },
            {
              type: "link",
              link: {
                name: "Support",
                path: pagePath.support,
              },
            },
            {
              type: "button",
              button: {
                name: "Sign Out",
                onClick: handleSignout,
              },
            },
          ]}
        />
        {children}
      </div>
    </ThemeProvider>
  );
};

export default Layout;
