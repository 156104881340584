import React from "react";
import MuiModal from "@mui/material/Modal";
import CloseIcon from '@mui/icons-material/Close';
import "./modal.css";

const Modal = ({ title = null, subTitle = null, onCloseIconClick = null, disableChildrenTopSpacing = false, customProps, children }) => {
    return (
        <MuiModal
            {...customProps}
        >
            <div className="modal-container" onClick={() => {
                document.querySelector(".MuiBackdrop-root").click();
            }}>
                <div className="modal" onClick={e => e.stopPropagation()}>
                    {title ? (
                        <div className="modal-header" style={disableChildrenTopSpacing === false ? { marginBottom: "30px" } : null}>
                            <div className="modal-header-title">
                                {title}
                                {subTitle}
                            </div>
                            {onCloseIconClick ? (
                                <button className="modal-close-button" onClick={onCloseIconClick}>
                                    <CloseIcon />
                                </button>
                            ) : null}
                        </div>
                    ) : null}
                    <div className="modal-children">
                        {children}
                    </div>
                </div>
            </div>
        </MuiModal>
    )
}

export default Modal;