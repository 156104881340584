import React, { Component } from "react";
import { connect } from "react-redux";
import Modal from "../customMui/modal/Modal";
import { handleSignout } from "../../util/functions/handleSignout";
import Button from "../customMui/Button";
import standardBizApiClient from "../../util/BizApi/standardBizApiClient";

let initTimeoutDelay = 60 * 17; // delay in seconds before timeout dialog 
let initTimeoutDuration = 60 * 2; // how long to display dialog in seconds
let timeoutDelay = initTimeoutDelay; 
let timeoutDuration = initTimeoutDuration; 
let timeRemaining = timeoutDelay + timeoutDuration;
let lastDate = new Date();
let currentDate = new Date();

class TimeoutWarning extends Component {
    constructor(props) {
        super(props);
        this.state = {
            timeRemainingText: "",
            showModal: false
        }
    }

    componentDidMount = () => {
        this.startTimeout();
        clearInterval(this.intervalId)
        this.intervalId = null;
        timeoutDelay = initTimeoutDelay;
        timeoutDuration = initTimeoutDuration;
        timeRemaining = timeoutDelay + timeoutDuration;
        lastDate = new Date();
        currentDate = new Date();
        this.startTimeout();
    }

    componentDidUpdate(oldProps) {
        const { last_request_url } = this.props;
        if(oldProps.last_request_url !== last_request_url && last_request_url !== "/business/account/signout") {
            clearInterval(this.intervalId);
            this.intervalId = null;
            timeoutDelay = initTimeoutDelay;
            timeoutDuration = initTimeoutDuration;
            timeRemaining = timeoutDelay + timeoutDuration;
            lastDate = new Date();
            currentDate = new Date();
            this.startTimeout();
        }
        if(last_request_url === "/business/account/signout") {
            clearInterval(this.intervalId);
            this.intervalId = null;
        }
    }


    extendSession = () => {
        standardBizApiClient.account_ping();
        clearInterval(this.intervalId);
        this.intervalId = null;
        timeoutDelay = initTimeoutDelay;
        timeoutDuration = initTimeoutDuration;
        timeRemaining = timeoutDelay + timeoutDuration;
        lastDate = new Date();
        currentDate = new Date();
        this.startTimeout();
        this.setState({
            timeRemainingText: "",
            showModal: false
        });
    }

    startTimeout = () => {
        return (
            this.intervalId = setInterval(() => {
                currentDate = new Date();
                timeRemaining = Math.round((timeoutDelay + timeoutDuration) - ((currentDate - lastDate) / 1000));

                if (document.getElementById("timeout-warning-container") === null) {
                    clearInterval(this.intervalId);
                    this.intervalId = null;
                }

                if(timeRemaining === timeoutDuration) {
                    const newTimeRemainingText = this.formatTimeRemaining(timeRemaining);
                    this.setState({
                        timeRemainingText: newTimeRemainingText,
                        showModal: true
                    });
                } else if(timeRemaining < timeoutDuration && timeRemaining > 0) {  
                    if (this.state.showModal === false) {
                        this.setState({ 
                            showModal: true
                        });
                    }
                    const newTimeRemainingText = this.formatTimeRemaining(timeRemaining);
                    this.setState({
                        timeRemainingText: newTimeRemainingText
                    });
                } else if(timeRemaining < 1){
                    clearInterval(this.intervalId);
                    this.intervalId = null;
                    handleSignout();
                }
            }, 1000)
        )
    }

    formatTimeRemaining = timeRemaining => {
        let minutes = Math.floor(timeRemaining / 60);
        if(minutes === 0) minutes = "";
        if(minutes === 1) minutes = minutes + " minute ";
        if(minutes > 1) minutes = minutes + " minutes ";

        let seconds = timeRemaining % 60;
        if (seconds === 0) seconds = "";
        if(seconds === 1) seconds = seconds + " second";
        if(seconds > 1) seconds = seconds + " seconds";

        return minutes + seconds;
    }

    render = () => {
        const { timeRemainingText, showModal } = this.state;

        return (
            <div id="timeout-warning-container" style={{ position: "relative", zIndex: 2000}}>
                {showModal ? (
                    <Modal 
                        customProps= {{open : showModal}}
                        title={<h6>Session Expiration Warning</h6>}
                    >
                        <p style={{ marginBottom: "30px" }}>
                            Due to inactivity, your session will expire in {timeRemainingText}.
                            <br/>
                            <br/>
                            Do you want to extend the session?
                        </p>
                        <div className="modal-actions">
                        <Button
                            variant= "outlined"
                            customProps={{ 
                                onClick: () => {
                                    clearInterval(this.intervalId),
                                    this.intervalId= null,
                                    handleSignout() 
                                },
                                sx: { margin:"0 10px" }
                            }}  
                            >
                            Sign Out
                        </Button>
                        <Button 
                            customProps={{ 
                                onClick: this.extendSession,
                                sx: { margin:"0 10px" }
                            }} 
                        > 
                            Extend Session
                        </Button>
                        </div>
                    </Modal>
                 ) : null} 
            </div>
        )
    }
}

const mapStateToProps = ({ network }) => {
    const { last_request_url } = network;
    return { last_request_url }
}

const mapDispatchToProps = dispatch => {
    return {
        
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(TimeoutWarning);