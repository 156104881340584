import React, { useState, useEffect } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import CertSubscribeInner from "./CertSubscribeInner";
import CertSubscribeSkeleton from "./CertSubscribeSkeleton";
import Alert from "../customMui/Alert";
import standardBizApiClient from "../../util/BizApi/standardBizApiClient";
import "./certSubscribe.css";

// stripe needs to be used in an Elements provider
// and the Elements provider requires the publishable key, which is stored in the backend
// so this wraps CertSubscribeInner with the Elements provider and handles getting the publishable key
const CertSubscribe = ({ certCode, certName, onCancel }) => {
    const [ stripePubKey, setStripePubKey ] = useState(null);
    const [ errorMessage, setErrorMessage ] = useState(null);

    useEffect(() => {
        handleSetStripePubKey();
    }, []);

    const handleSetStripePubKey = async () => {
        const stripePubKeyRes = await standardBizApiClient.param_stripe_pub_key();
        if (stripePubKeyRes?.is_error || !certCode) {
            setErrorMessage(
                <div>
                    Error while gathering certification data. <br/>
                    Please refresh the page and try again, or reach out to us at <a href="mailto:support@wannapractice.com">support@wannapractice.com</a>
                </div>
            )
            return;
        }
        const newStripePubKey = stripePubKeyRes.stripe_pub_key;
        setStripePubKey(newStripePubKey);
    }

    if (errorMessage) {
        return <Alert severity="error">{errorMessage}</Alert>;
    }

    if (!stripePubKey) {
        return <CertSubscribeSkeleton certCode={certCode} certName={certName} onCancel={onCancel} />
    }

    return (
        <Elements stripe={loadStripe(stripePubKey)}>
            <CertSubscribeInner 
                certCode={certCode} 
                certName={certName} 
                onCancel={onCancel}
            />
        </Elements>
    )
}

export default CertSubscribe;
