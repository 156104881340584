import { createSlice } from "@reduxjs/toolkit";

export const practiceSlice = createSlice({
    name: "practice",
    initialState: {
        certList: [],
        certCodeSubscribedList: [],
        certCodeInFocus: null,
        selectedPracticeTab: "domains",
        selectedNumberOfQuestions: 10
    },
    reducers: {
        setCertList: (state, action) => {
            state.certList = action.payload;
        },
        setCertCodeSubscribedList: (state, action) => {
            state.certCodeSubscribedList = action.payload;
        },
        setCertCodeInFocus: (state, action) => {
            state.certCodeInFocus = action.payload;
        },
        setSelectedPracticeTab: (state, action) => {
            state.selectedPracticeTab = action.payload;
        },
        setSelectedNumberOfQuestions: (state, action) => {
            state.selectedNumberOfQuestions = action.payload;
        }
    }
})

export const {
    setCertList,
    setCertCodeSubscribedList,
    setCertCodeInFocus,
    setSelectedPracticeTab,
    setSelectedNumberOfQuestions
} = practiceSlice.actions

export default practiceSlice.reducer