import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import Banner from '../../components/customMui/Banner';
import pagePath from "../../util/constants/pagePath";

const AccountBanner = () => {
  const { platform, os } = useSelector((state) => state.device_context);

  const bannerText = "Manage your subscriptions on our website, then return to the app to keep practicing.";
  const buttonText = "Manage Subscriptions"
  const continuePath= pagePath.ma_redirect_account 
  const toolBarStyle = { paddingX : 0 };

  const onClickAction =useMemo(() => {

    if (platform === "mobile_app" && os === "ios") {
      return () => window.location.href = continuePath;
    }
    return undefined;
  }, [platform, os]);


  return (

    <Banner
      bannerText={bannerText} 
      buttonText={buttonText} 
      toolBarStyle={toolBarStyle}
      onClickAction={onClickAction}
    />

  );
};

export default AccountBanner;
