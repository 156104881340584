import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux"; 
import {
    setIsExamInProgress, 
    setTimeRemainingSeconds
} from "../../../../redux/slices/examSlice";
import standardBizApiClient from "../../../../util/BizApi/standardBizApiClient";

/*
    PracticeTimer
        - iterates the timeRemainingSeconds in redux
        - handles ending the exam if it has ran out of time

        - returns null
*/

const PracticeTimer = () => {
    const { timeRemainingSeconds } = useSelector(state => state.exam);
    const dispatch = useDispatch();
    let timerInterval = null;
    
    useEffect(() => {
        handleStartInterval();

        return () => {
            handleStopInterval();
        }
    })

    const handleStartInterval = () => {
        timerInterval = setInterval(() => {
            const newTimeRemainingSeconds = timeRemainingSeconds - 1;
            if (newTimeRemainingSeconds <= 0) {
                endExamIfExpired();
                return;
            }
            dispatch(setTimeRemainingSeconds(newTimeRemainingSeconds));
        }, 1000)
    }

    const handleStopInterval = () => {
        if (timerInterval) {
            clearTimeout(timerInterval);
        }
    }

    const endExamIfExpired = async () => {
        const pingRes = await standardBizApiClient.exam_ping();
        if (pingRes?.is_error) {
            return;
        }
        const { is_expired, time_remaining_seconds } = pingRes;
        dispatch(setTimeRemainingSeconds(time_remaining_seconds));
        if (is_expired) {
            dispatch(setIsExamInProgress(false));
            handleStopInterval();
        }
    }

    return null;
}

export default PracticeTimer;