import React from 'react';
import { AppBar, Toolbar, Alert, Button } from '@mui/material';


const Banner = ({ bannerText, buttonText ,severity ="info", position ="static", toolBarStyle ,onClickAction ,appBarStyle}) => {
  return (
    <AppBar position={position} color="default" sx={{ ...appBarStyle}} >
      <Toolbar variant="dense" sx={{ ...toolBarStyle }} >
        <Alert
          severity={severity}
          action={
            <React.Fragment>
              { buttonText && onClickAction &&
                <Button                
                  size='small'
                  onClick={onClickAction}
                >
                  {buttonText}
                </Button>
              }
            </React.Fragment>
          }
          sx={{ width: '100%', display: 'flex', alignItems: 'center' }}
        >
          {bannerText}
        </Alert>
      </Toolbar>
    </AppBar>
  );
};

export default Banner;
