import { useSelector } from "react-redux";

const useIsCertSubscribeAllowed = () => {
    const { platform, os } = useSelector((state) => state.device_context);

    const isCertSubscribeAllowed = () => {
        // ios app users need to use the browser to subscribe
        if (platform === "mobile_app" && os === "ios") {
            return false;
        }
        return true;
    }

    return [
        isCertSubscribeAllowed
    ];
}

export default useIsCertSubscribeAllowed;