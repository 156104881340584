import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setCertList, setCertCodeSubscribedList, setCertCodeInFocus } from "../../redux/slices/practiceSlice";
import PageWrapper from "../pageWrapper/PageWrapper";
import Button from "../customMui/Button";
import Alert from "../customMui/Alert";
import standardBizApiClient from "../../util/BizApi/standardBizApiClient";
import pagePath from "../../util/constants/pagePath";
import "../../pages/practice/practiceSubFirstCertFlow/practiceSubFirstCertFlow.css";

const CertSubscribeSuccess = () => {
    const [ certSubscribeErrorMessage, setCertSubscribeErrorMessage ] = useState(null);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        refreshCerts();
    }, []);

    const refreshCerts = async () => {
        const certListRes = await standardBizApiClient.certification_list();
        if (certListRes?.is_error) {
            setCertSubscribeErrorMessage("Your subscription was successfully created. But there was an error while retrieving it, please refresh the page to try again.");
            return;
        }
        const { cert_code_in_focus, cert_code_subscribed_list, cert_list } = certListRes.certs;
        dispatch(setCertCodeInFocus(cert_code_in_focus));
        dispatch(setCertCodeSubscribedList(cert_code_subscribed_list));
        dispatch(setCertList(cert_list));
    }

    return (
        <PageWrapper 
            style={{ padding: "0 !important" }}
            title="Thank You!"
            subTitle="Now you can get started with domain-specific practice exams and full certification practice tests!"
        >
            {certSubscribeErrorMessage ? (
                <Alert severity="error" style={{ marginTop: "20px" }}>
                    {certSubscribeErrorMessage}
                </Alert> 
            ) : null}
            <div className="practice-subscribe-cert-button-list-container">
                <div className="practice-subscribe-cert-button-wrapper">
                    <Button
                        customProps={{
                            onClick: () => {
                                navigate(pagePath.practice)
                            }
                        }} 
                    >
                        Continue
                    </Button>
                </div>   
            </div>                
        </PageWrapper>
    )
}

export default CertSubscribeSuccess;