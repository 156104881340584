import React from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { persistStore } from "redux-persist";
import { PersistGate } from "redux-persist/integration/react";
import AppRouter from "./AppRouter";
import store from "./redux/store";
import requestInterceptor from "./util/functions/requestInterceptor";
import "./styles/main.css";

let persistor = persistStore(store);
requestInterceptor.setup(store);

const container = document.getElementById("root");
const root = createRoot(container);
root.render(
    <Provider store={store}>
        <PersistGate persistor={persistor}>
            <AppRouter />
        </PersistGate>
    </Provider>
);