import React, { useState, useEffect } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import StripeCardElementFormInner from "./StripeCardElementFormInner";
import { Skeleton } from "@mui/material";
import Alert from "../customMui/Alert";
import standardBizApiClient from "../../util/BizApi/standardBizApiClient";

// stripe needs to be used in an Elements provider
// and the Elements provider requires the publishable key, which is stored in the backend
// so this wraps StripeCardElementFormInner with the Elements provider and handles getting the publishable key
const StripeCardElementForm = ({ onCancel, onSubmit }) => {
    const [ stripePubKey, setStripePubKey ] = useState(null);
    const [ errorMessage, setErrorMessage ] = useState(null);

    useEffect(() => {
        handleSetStripePubKey();
    }, []);

    const handleSetStripePubKey = async () => {
        const stripePubKeyRes = await standardBizApiClient.param_stripe_pub_key();
        if (stripePubKeyRes?.is_error) {
            setErrorMessage(
                <div>
                    Error while preparing payment card form. <br/>
                    Please refresh the page and try again, or reach out to us at <a href="mailto:support@wannapractice.com">support@wannapractice.com</a>
                </div>
            )
            return;
        }
        const newStripePubKey = stripePubKeyRes.stripe_pub_key;
        setStripePubKey(newStripePubKey);
    }

    if (errorMessage) {
        return <Alert severity="error">{errorMessage}</Alert>;
    }

    if (!stripePubKey) {
        return <Skeleton animation="wave" sx={{ width: "200px" }} />
    }

    return (
        <Elements stripe={loadStripe(stripePubKey)}>
            <StripeCardElementFormInner 
                onCancel={onCancel}
                onSubmit={onSubmit}
            />
        </Elements>
    )
}

export default StripeCardElementForm;
