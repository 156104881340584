import React, { useState } from "react";
import { useParams, Link } from "react-router-dom";
import TextField from "../../components/customMui/TextField";
import Button from "../../components/customMui/Button";
import LoadingButton from "../../components/customMui/LoadingButton";
import Alert from "../../components/customMui/Alert";
import standardBizApiClient from "../../util/BizApi/standardBizApiClient";
import pagePath from "../../util/constants/pagePath";
import "./signupConfirm.css";

const SignupConfirm = () => {
    const [ submitLoading, setSubmitLoading ] = useState(false);
    const [ showError, setShowError ] = useState(false);
    const [ showSuccess, setShowSuccess ] = useState(false);
    const params = useParams();

    const handleSignupConfirm = async e => {
        e.preventDefault();
        setSubmitLoading(true);
        setShowError(false);

        const emailAddress = document.getElementById("signup-confirm-email-address")?.value;
        const token = params?.token;

        const signupConfirmRes = await standardBizApiClient.account_signup_confirm(emailAddress, token);
        if (signupConfirmRes?.is_error) {
            setShowError(true);
            setSubmitLoading(false);
            return;
        }

        setShowSuccess(true);
        setSubmitLoading(false);
    }

    if (showSuccess) {
        return (
            <div className="signup-confirm signup-confirm-success">
                <h2 className="font-header-light">Welcome to WannaPractice!</h2>
                <p>Your account is ready to use.</p>
                <Link to={pagePath.login}>
                    <Button>Continue to Login</Button>
                </Link>
            </div>
        )
    }

    return (
        <div className="signup-confirm">
            <h2 className="font-header-light">Confirm Email</h2>
            <form className="signup-confirm-form" onSubmit={handleSignupConfirm}>
                {showError ? (<Alert severity="error">Error while confirming email, please check the entered email.</Alert>) : null}
                <TextField 
                    required
                    disabled={submitLoading}
                    id="signup-confirm-email-address"
                    type="email"
                    label="Email Address"
                />
                <LoadingButton customProps={{ type:"submit", loading: submitLoading }}>Confirm</LoadingButton>
            </form>
        </div>
    )
}

export default SignupConfirm;