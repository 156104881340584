import BizApiAxiosClient from "./BizApiAxiosClient";
import ClientErrorDecorator from "./decorator/ClientErrorDecorator";
import AxiosErrorTransformer from "./strategy/AxiosErrorTransformer";

// returns a common use bizapi client decorated by an error handler

const bizApiAxiosClient = new BizApiAxiosClient();

const axiosErrorTransformer = new AxiosErrorTransformer();

const clientErrorDecorator = new ClientErrorDecorator(
    bizApiAxiosClient,
    axiosErrorTransformer
)

const standardBizApiClient = clientErrorDecorator;

export default standardBizApiClient