import React, { useEffect, useState } from "react";
import LoadingButton from "../../../../components/customMui/LoadingButton";
import { Skeleton } from "@mui/material";
import PracticeAnswer from "../practiceAnswer/PracticeAnswer";
import "./practiceQuestionAnswer.css";

const PracticeQuestionAnswerFull = ({
    questionAnswerData,
    isLastQuestion,
    onAnswerSubmit,
    onNextQuestion,
    showPageLoader
}) => {
    const [ formattedQuestionAnswer, setFormattedQuestionAnswer ] = useState(null);
    const [ selectedOption, setSelectedOption ] = useState(null);
    const [ submitAnswerLoader, setSubmitAnswerLoader ] = useState(false);
    const [ lockAnswerOptions, setLockAnswerOptions ] = useState(false);

    useEffect(() => {
        if (questionAnswerData) {
            handleFormatQuestionAnswer();
        }
    }, [questionAnswerData])

    const handleFormatQuestionAnswer = () => {
        let newFormattedQuestionAnswer = {
            question_text: questionAnswerData.text,
            options: [],
        }
        for (const optionKey in questionAnswerData.options) {
            newFormattedQuestionAnswer.options.push({
                key: optionKey,
                name: questionAnswerData.options[optionKey]
            })
        }
        setLockAnswerOptions(false);
        setFormattedQuestionAnswer(newFormattedQuestionAnswer);
        setSelectedOption(null);
    }

    const handleSelectOption = option => {
        if (submitAnswerLoader || lockAnswerOptions) return;
        if (selectedOption === option) {
            setSelectedOption(null);
            return;
        }
        setSelectedOption(option);
    }

    const handleSubmitAnswer = async () => {
        if (submitAnswerLoader || !selectedOption) return;
        setLockAnswerOptions(true);
        setSubmitAnswerLoader(true);
        const submitAnswerRes = await onAnswerSubmit(selectedOption);
        if (!submitAnswerRes) {
            setSubmitAnswerLoader(false);
            return;
        }
        const nextQuestionRes = await onNextQuestion();
        if (!nextQuestionRes) {
            setSubmitAnswerLoader(false);
            return;
        }
        setSubmitAnswerLoader(false);
    }

    const getAnswerState = (option) => {
        if (submitAnswerLoader) {
            if (option != selectedOption) {
                return "disabled";
            }
        }
        if (option == selectedOption) {
            return "selected";
        }
        return "";
    }

    if (showPageLoader || !formattedQuestionAnswer) {
        return (
            <div style={{ width: "100%" }}>
                <Skeleton animation="wave" sx={{ width: "95%", marginTop: "15px" }} />
                <Skeleton animation="wave" sx={{ width: "98%" }} />
                <Skeleton animation="wave" sx={{ width: "92%" }} />
                <Skeleton animation="wave" sx={{ width: "95%" }} />
                <Skeleton animation="wave" sx={{ width: "65%", marginBottom: "30px" }} />

                <PracticeAnswer answerState="loading" />
                <PracticeAnswer answerState="loading" />
                <PracticeAnswer answerState="loading" />
                <PracticeAnswer answerState="loading" />

                <Skeleton animation="wave" sx={{ width: "100%", marginTop: "30px", height: "70px" }} />
            </div>
        )
    }

    return (
        <div style={{ width: "100%" }}>
            <p style={{ marginBottom: "10px" }}>{formattedQuestionAnswer.question_text}</p>
            <div className="practice-qa-answer-container">
                {formattedQuestionAnswer.options.map((option, index) => {
                    return (    
                        <div key={index}>                
                            <PracticeAnswer 
                                answerData={option}
                                answerState={getAnswerState(option.key)} 
                                onClick={(key) => handleSelectOption(key)}
                            />
                        </div>
                    )
                })}
            </div>
            <div className="practice-qa-answer-button-container">
                <LoadingButton 
                    customProps={{
                        onClick: handleSubmitAnswer,
                        loading: submitAnswerLoader,
                        disabled: submitAnswerLoader || selectedOption === null
                    }}
                >
                    Submit
                </LoadingButton>
            </div>
        </div>
    )
}

export default PracticeQuestionAnswerFull;
