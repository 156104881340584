import { createSlice } from "@reduxjs/toolkit"

const initialState = {
    example_id: "",
    other_id: ""
}

export const ExampleSlice = createSlice({
    name: "example",
    initialState: initialState,
    reducers: {
        setExampleId: (state, action) => {
            state.example_id = action.payload;
        },
        setOtherId: (state, action) => {
            state.other_id = action.payload;
        },
        resetExample: (state, action) => {
            // looping keys because we can't set state = initialState
            // probably a better way of doing this. havent looked into it yet
            for (const key in initialState) {
                state[key] = initialState[key];
            }
        }
    }
})

export const { setExampleId, setOtherId, resetExample } = ExampleSlice.actions

export default ExampleSlice.reducer