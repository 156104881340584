import React from "react";
import Button from "../../components/customMui/Button";
import "./whileBrowserRedirect.css";

// shown while the user is being redirected to their browser
const WhileBrowserRedirect = ({
    continuePath
}) => {
    return (
        <div className="while-browser-redirect">
            <h5 className="font-header-light">Redirecting to your browser...</h5>
            <p>Click the button below to return to the app</p>
            <Button                
                variant="contained"
                customProps={{
                    onClick: () => {
                        // this refreshes the page on purpose...
                        // because we dont know what the user changed in the webapp
                        window.location.href = continuePath
                    }
                }}
            >
                Back
            </Button> 
        </div>
    )
}

export default WhileBrowserRedirect;