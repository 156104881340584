import React from "react";
import MuiCircularProgress, { circularProgressClasses }  from "@mui/material/CircularProgress";
import getCssVariable from "../../util/functions/getCssVariable";

const CircularProgress = ({ value, min=0, max=100, size=40, thickness=4, color=null, backgroundColor=null, label=null, style={} }) => {

    const primaryColor = getCssVariable("--wnp-color-primary");
    const secondaryExtralightColor = getCssVariable("--wnp-color-secondary-extralight");

    const normalizedValueFromRange = value => {
        return ((value - min) * 100) / (max - min);
    }

    return (
        <div style={{ display: "flex", alignItems: "center", justifyContent: "center", position: "relative", ...style }}>
            <MuiCircularProgress
                variant="determinate"
                sx={{
                    color: backgroundColor ? backgroundColor : secondaryExtralightColor,
                    position: "absolute"
                }}
                size={size}
                thickness={thickness}
                value={100}
            />
            <MuiCircularProgress
                variant="determinate"
                sx={{
                    color: color ? color : primaryColor,
                    position: "absolute"
                }}
                size={size}
                thickness={thickness}
                value={normalizedValueFromRange(value)}
            />
            <div style={{ height: `${size}px`, width: `${size}px`, display: "flex", alignItems: "center", justifyContent: "center" }}>
                {label}
            </div>
        </div>
    )
}

export default CircularProgress;